:root {
  --topbar-height: 4rem;
  --toolbar-height: 10rem;
  --total-header: calc(var(--toolbar-height)  + var(--topbar-height));
}

@media (width >= 640px) {
  :root {
    --toolbar-height: 7rem;
  }
}

:root {
  --line-height-multiplier: 1;
  --highlight-1: #a9bdd5;
  --highlight-2: #dabcb5;
  --highlight-3: #a6c5be;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden]:where(:not([hidden="until-found"])) {
  display: none;
}

:root, [data-theme] {
  background-color: var(--fallback-b1, oklch(var(--b1) / 1));
  color: var(--fallback-bc, oklch(var(--bc) / 1));
}

@supports not (color: oklch(0% 0 0)) {
  :root {
    --lightningcss-light: initial;
    --lightningcss-dark: ;
    color-scheme: light;
    --fallback-p: #491eff;
    --fallback-pc: #d4dbff;
    --fallback-s: #ff41c7;
    --fallback-sc: #fff9fc;
    --fallback-a: #00cfbd;
    --fallback-ac: #00100d;
    --fallback-n: #2b3440;
    --fallback-nc: #d7dde4;
    --fallback-b1: #fff;
    --fallback-b2: #e5e6e6;
    --fallback-b3: #e5e6e6;
    --fallback-bc: #1f2937;
    --fallback-in: #00b3f0;
    --fallback-inc: #000;
    --fallback-su: #00ca92;
    --fallback-suc: #000;
    --fallback-wa: #ffc22d;
    --fallback-wac: #000;
    --fallback-er: #ff6f70;
    --fallback-erc: #000;
  }

  @media (prefers-color-scheme: dark) {
    :root {
      --lightningcss-light: ;
      --lightningcss-dark: initial;
      color-scheme: dark;
      --fallback-p: #7582ff;
      --fallback-pc: #050617;
      --fallback-s: #ff71cf;
      --fallback-sc: #190211;
      --fallback-a: #00c7b5;
      --fallback-ac: #000e0c;
      --fallback-n: #2a323c;
      --fallback-nc: #a6adbb;
      --fallback-b1: #1d232a;
      --fallback-b2: #191e24;
      --fallback-b3: #15191e;
      --fallback-bc: #a6adbb;
      --fallback-in: #00b3f0;
      --fallback-inc: #000;
      --fallback-su: #00ca92;
      --fallback-suc: #000;
      --fallback-wa: #ffc22d;
      --fallback-wac: #000;
      --fallback-er: #ff6f70;
      --fallback-erc: #000;
    }
  }
}

html {
  -webkit-tap-highlight-color: transparent;
}

* {
  scrollbar-color: color-mix(in oklch, currentColor 35%, transparent) transparent;
}

:hover {
  scrollbar-color: color-mix(in oklch, currentColor 60%, transparent) transparent;
}

:root {
  --lightningcss-light: initial;
  --lightningcss-dark: ;
  color-scheme: light;
  --in: 72.06% .191 231.6;
  --su: 64.8% .15 160;
  --wa: 84.71% .199 83.87;
  --er: 71.76% .221 22.18;
  --pc: 89.824% .06192 275.75;
  --ac: 15.352% .0368 183.61;
  --inc: 0% 0 0;
  --suc: 0% 0 0;
  --wac: 0% 0 0;
  --erc: 0% 0 0;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 49.12% .3096 275.75;
  --s: 59.1% .24998 345.99;
  --sc: 98.71% .0106 342.55;
  --a: 76.76% .184 183.61;
  --n: 32.1785% .02476 255.702;
  --nc: 89.4994% .011585 252.096;
  --b1: 100% 0 0;
  --b2: 96.1151% 0 0;
  --b3: 92.4169% .00108 197.138;
  --bc: 27.8078% .029596 256.848;
}

@media (prefers-color-scheme: dark) {
  :root {
    --lightningcss-light: ;
    --lightningcss-dark: initial;
    color-scheme: dark;
    --in: 72.06% .191 231.6;
    --su: 64.8% .15 160;
    --wa: 84.71% .199 83.87;
    --er: 71.76% .221 22.18;
    --pc: 13.138% .0392 275.75;
    --sc: 14.96% .052 342.55;
    --ac: 14.902% .0334 183.61;
    --inc: 0% 0 0;
    --suc: 0% 0 0;
    --wac: 0% 0 0;
    --erc: 0% 0 0;
    --rounded-box: 1rem;
    --rounded-btn: .5rem;
    --rounded-badge: 1.9rem;
    --animation-btn: .25s;
    --animation-input: .2s;
    --btn-focus-scale: .95;
    --border-btn: 1px;
    --tab-border: 1px;
    --tab-radius: .5rem;
    --p: 65.69% .196 275.75;
    --s: 74.8% .26 342.55;
    --a: 74.51% .167 183.61;
    --n: 31.3815% .021108 254.139;
    --nc: 94.39% .0211 254.14;
    --b1: 25.3267% .015896 252.418;
    --b2: 23.2607% .013807 253.101;
    --b3: 21.1484% .01165 254.088;
    --bc: 91.96% .0159 252.42;
  }
}

[data-theme="light"] {
  --lightningcss-light: initial;
  --lightningcss-dark: ;
  color-scheme: light;
  --in: 72.06% .191 231.6;
  --su: 64.8% .15 160;
  --wa: 84.71% .199 83.87;
  --er: 71.76% .221 22.18;
  --pc: 89.824% .06192 275.75;
  --ac: 15.352% .0368 183.61;
  --inc: 0% 0 0;
  --suc: 0% 0 0;
  --wac: 0% 0 0;
  --erc: 0% 0 0;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 49.12% .3096 275.75;
  --s: 59.1% .24998 345.99;
  --sc: 98.71% .0106 342.55;
  --a: 76.76% .184 183.61;
  --n: 32.1785% .02476 255.702;
  --nc: 89.4994% .011585 252.096;
  --b1: 100% 0 0;
  --b2: 96.1151% 0 0;
  --b3: 92.4169% .00108 197.138;
  --bc: 27.8078% .029596 256.848;
}

[data-theme="dark"] {
  --lightningcss-light: ;
  --lightningcss-dark: initial;
  color-scheme: dark;
  --in: 72.06% .191 231.6;
  --su: 64.8% .15 160;
  --wa: 84.71% .199 83.87;
  --er: 71.76% .221 22.18;
  --pc: 13.138% .0392 275.75;
  --sc: 14.96% .052 342.55;
  --ac: 14.902% .0334 183.61;
  --inc: 0% 0 0;
  --suc: 0% 0 0;
  --wac: 0% 0 0;
  --erc: 0% 0 0;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 65.69% .196 275.75;
  --s: 74.8% .26 342.55;
  --a: 74.51% .167 183.61;
  --n: 31.3815% .021108 254.139;
  --nc: 94.39% .0211 254.14;
  --b1: 25.3267% .015896 252.418;
  --b2: 23.2607% .013807 253.101;
  --b3: 21.1484% .01165 254.088;
  --bc: 91.96% .0159 252.42;
}

[data-theme="retro"] {
  --lightningcss-light: initial;
  --lightningcss-dark: ;
  color-scheme: light;
  --inc: 90.923% .043042 262.881;
  --suc: 12.541% .033982 149.214;
  --wac: 13.3168% .031484 58.3183;
  --erc: 13.144% .0398 27.33;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --p: 50.4% .171 22.665;
  --pc: 93.4% .06 53;
  --s: 53.1% .09 159.09;
  --sc: 96.2% .033 129;
  --a: 70.3919% .125455 52.9534;
  --ac: 26.5104% .006243 .522862;
  --n: 28.4181% .009519 355.534;
  --nc: 92.5604% .025113 89.2173;
  --b1: 91.6374% .034554 90.5158;
  --b2: 88.2722% .049418 91.7743;
  --b3: 84.133% .065952 90.8567;
  --bc: 26.5104% .006243 .522862;
  --in: 54.615% .215208 262.881;
  --su: 62.7052% .169912 149.214;
  --wa: 66.584% .157422 58.3183;
  --er: 65.72% .199 27.33;
  --rounded-box: .4rem;
  --rounded-btn: .4rem;
  --rounded-badge: .4rem;
  --tab-radius: .4rem;
}

@font-face {
  font-family: Biancoenero;
  src: url("BiancoeneroRegular.1959e38b.woff2") format("woff2");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: Biancoenero;
  src: url("BiancoeneroBold.667b97b3.woff2") format("woff2");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: Biancoenero;
  src: url("BiancoeneroItalic.b75d6923.woff2") format("woff2");
  font-style: italic;
  font-weight: normal;
}

@font-face {
  font-family: Biancoenero;
  src: url("BiancoeneroBoldItalic.13c404c4.woff2") format("woff2");
  font-style: italic;
  font-weight: bold;
}

.alert {
  text-align: center;
  border-radius: var(--rounded-box, 1rem);
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-border-opacity)));
  --tw-text-opacity: 1;
  width: 100%;
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  --alert-bg: var(--fallback-b2, oklch(var(--b2) / 1));
  --alert-bg-mix: var(--fallback-b1, oklch(var(--b1) / 1));
  background-color: var(--alert-bg);
  grid-auto-flow: row;
  align-content: flex-start;
  place-items: center;
  gap: 1rem;
  padding: 1rem;
  display: grid;
}

@media (width >= 640px) {
  .alert {
    text-align: start;
    grid-template-columns: auto minmax(auto, 1fr);
    grid-auto-flow: column;
    justify-items: start;
  }
}

.avatar.placeholder > div {
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (hover: hover) {
  .link-hover:hover {
    text-decoration-line: underline;
  }

  .label a:hover {
    --tw-text-opacity: 1;
    color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  }

  .menu li > :not(ul, .menu-title, details, .btn):active, .menu li > :not(ul, .menu-title, details, .btn).active, .menu li > details > summary:active {
    --tw-bg-opacity: 1;
    background-color: var(--fallback-n, oklch(var(--n) / var(--tw-bg-opacity)));
    --tw-text-opacity: 1;
    color: var(--fallback-nc, oklch(var(--nc) / var(--tw-text-opacity)));
  }
}

.btn {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border-radius: var(--rounded-btn, .5rem);
  border-color: #0000;
  border-color: oklch(var(--btn-color, var(--b2)) / var(--tw-border-opacity));
  text-align: center;
  border-width: var(--border-btn, 1px);
  --tw-text-opacity: 1;
  height: 3rem;
  min-height: 3rem;
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  outline-color: var(--fallback-bc, oklch(var(--bc) / 1));
  background-color: oklch(var(--btn-color, var(--b2)) / var(--tw-bg-opacity));
  --tw-bg-opacity: 1;
  --tw-border-opacity: 1;
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1em;
  text-decoration-line: none;
  transition-property: color, background-color, border-color, opacity, box-shadow, transform;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  display: inline-flex;
}

.btn-disabled, .btn[disabled], .btn:disabled {
  pointer-events: none;
}

.btn-square {
  width: 3rem;
  height: 3rem;
  padding: 0;
}

:where(.btn:is(input[type="checkbox"])), :where(.btn:is(input[type="radio"])) {
  appearance: none;
  width: auto;
}

.btn:is(input[type="checkbox"]):after, .btn:is(input[type="radio"]):after {
  --tw-content: attr(aria-label);
  content: var(--tw-content);
}

.card {
  border-radius: var(--rounded-box, 1rem);
  flex-direction: column;
  display: flex;
  position: relative;
}

.card:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.card figure {
  justify-content: center;
  align-items: center;
  display: flex;
}

.card.image-full {
  display: grid;
}

.card.image-full:before {
  content: "";
  z-index: 10;
  border-radius: var(--rounded-box, 1rem);
  --tw-bg-opacity: 1;
  background-color: var(--fallback-n, oklch(var(--n) / var(--tw-bg-opacity)));
  opacity: .75;
  position: relative;
}

.card.image-full:before, .card.image-full > * {
  grid-row-start: 1;
  grid-column-start: 1;
}

.card.image-full > figure img {
  object-fit: cover;
  height: 100%;
}

.card.image-full > .card-body {
  z-index: 20;
  --tw-text-opacity: 1;
  color: var(--fallback-nc, oklch(var(--nc) / var(--tw-text-opacity)));
  position: relative;
}

.checkbox {
  --chkbg: var(--fallback-bc, oklch(var(--bc) / 1));
  --chkfg: var(--fallback-b1, oklch(var(--b1) / 1));
  cursor: pointer;
  appearance: none;
  border-radius: var(--rounded-btn, .5rem);
  border-width: 1px;
  border-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-border-opacity)));
  --tw-border-opacity: .2;
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
}

.collapse:not(td):not(tr):not(colgroup) {
  visibility: visible;
}

.collapse {
  border-radius: var(--rounded-box, 1rem);
  grid-template-rows: max-content 0fr;
  width: 100%;
  transition: grid-template-rows .2s;
  display: grid;
  position: relative;
  overflow: hidden;
}

.collapse-title, .collapse > input[type="checkbox"], .collapse > input[type="radio"], .collapse-content {
  grid-row-start: 1;
  grid-column-start: 1;
}

.collapse > input[type="checkbox"], .collapse > input[type="radio"] {
  appearance: none;
  opacity: 0;
}

:where(.collapse > input[type="checkbox"]), :where(.collapse > input[type="radio"]) {
  z-index: 1;
  width: 100%;
  height: 100%;
}

.collapse[open], .collapse-open, .collapse:focus:not(.collapse-close), .collapse:not(.collapse-close):has( > input[type="checkbox"]:checked), .collapse:not(.collapse-close):has( > input[type="radio"]:checked) {
  grid-template-rows: max-content 1fr;
}

.collapse[open] > .collapse-content, .collapse-open > .collapse-content, .collapse:focus:not(.collapse-close) > .collapse-content, .collapse:not(.collapse-close) > input[type="checkbox"]:checked ~ .collapse-content, .collapse:not(.collapse-close) > input[type="radio"]:checked ~ .collapse-content {
  visibility: visible;
  min-height: fit-content;
}

.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown > :not(summary):focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.dropdown .dropdown-content {
  position: absolute;
}

.dropdown:not(details) .dropdown-content {
  visibility: hidden;
  opacity: 0;
  transform-origin: top;
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.dropdown-end .dropdown-content {
  inset-inline-end: 0;
}

.dropdown-end.dropdown-right .dropdown-content, .dropdown-end.dropdown-left .dropdown-content {
  top: auto;
  bottom: 0;
}

.dropdown.dropdown-open .dropdown-content, .dropdown:not(.dropdown-hover):focus .dropdown-content, .dropdown:focus-within .dropdown-content {
  visibility: visible;
  opacity: 1;
}

@media (hover: hover) {
  .dropdown.dropdown-hover:hover .dropdown-content {
    visibility: visible;
    opacity: 1;
  }

  .btm-nav > .disabled:hover, .btm-nav > [disabled]:hover {
    pointer-events: none;
    --tw-border-opacity: 0;
    background-color: var(--fallback-n, oklch(var(--n) / var(--tw-bg-opacity)));
    --tw-bg-opacity: .1;
    color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
    --tw-text-opacity: .2;
  }

  .btn:hover {
    --tw-border-opacity: 1;
    border-color: var(--fallback-b3, oklch(var(--b3) / var(--tw-border-opacity)));
    --tw-bg-opacity: 1;
    background-color: var(--fallback-b3, oklch(var(--b3) / var(--tw-bg-opacity)));
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .btn:hover {
      background-color: color-mix(in oklab, oklch(var(--btn-color, var(--b2)) / var(--tw-bg-opacity, 1)) 90%, black);
      border-color: color-mix(in oklab, oklch(var(--btn-color, var(--b2)) / var(--tw-border-opacity, 1)) 90%, black);
    }
  }

  @supports not (color: oklch(0% 0 0)) {
    .btn:hover {
      background-color: var(--btn-color, var(--fallback-b2));
      border-color: var(--btn-color, var(--fallback-b2));
    }
  }

  .btn.glass:hover {
    --glass-opacity: 25%;
    --glass-border-opacity: 15%;
  }

  .btn-ghost:hover {
    border-color: #0000;
  }

  @supports (color: oklch(0% 0 0)) {
    .btn-ghost:hover {
      background-color: var(--fallback-bc, oklch(var(--bc) / .2));
    }
  }

  .btn-outline:hover {
    --tw-border-opacity: 1;
    border-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-border-opacity)));
    --tw-bg-opacity: 1;
    background-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-bg-opacity)));
    --tw-text-opacity: 1;
    color: var(--fallback-b1, oklch(var(--b1) / var(--tw-text-opacity)));
  }

  .btn-outline.btn-primary:hover {
    --tw-text-opacity: 1;
    color: var(--fallback-pc, oklch(var(--pc) / var(--tw-text-opacity)));
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .btn-outline.btn-primary:hover {
      background-color: color-mix(in oklab, var(--fallback-p, oklch(var(--p) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-p, oklch(var(--p) / 1)) 90%, black);
    }
  }

  .btn-outline.btn-secondary:hover {
    --tw-text-opacity: 1;
    color: var(--fallback-sc, oklch(var(--sc) / var(--tw-text-opacity)));
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .btn-outline.btn-secondary:hover {
      background-color: color-mix(in oklab, var(--fallback-s, oklch(var(--s) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-s, oklch(var(--s) / 1)) 90%, black);
    }
  }

  .btn-outline.btn-accent:hover {
    --tw-text-opacity: 1;
    color: var(--fallback-ac, oklch(var(--ac) / var(--tw-text-opacity)));
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .btn-outline.btn-accent:hover {
      background-color: color-mix(in oklab, var(--fallback-a, oklch(var(--a) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-a, oklch(var(--a) / 1)) 90%, black);
    }
  }

  .btn-outline.btn-success:hover {
    --tw-text-opacity: 1;
    color: var(--fallback-suc, oklch(var(--suc) / var(--tw-text-opacity)));
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .btn-outline.btn-success:hover {
      background-color: color-mix(in oklab, var(--fallback-su, oklch(var(--su) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-su, oklch(var(--su) / 1)) 90%, black);
    }
  }

  .btn-outline.btn-info:hover {
    --tw-text-opacity: 1;
    color: var(--fallback-inc, oklch(var(--inc) / var(--tw-text-opacity)));
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .btn-outline.btn-info:hover {
      background-color: color-mix(in oklab, var(--fallback-in, oklch(var(--in) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-in, oklch(var(--in) / 1)) 90%, black);
    }
  }

  .btn-outline.btn-warning:hover {
    --tw-text-opacity: 1;
    color: var(--fallback-wac, oklch(var(--wac) / var(--tw-text-opacity)));
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .btn-outline.btn-warning:hover {
      background-color: color-mix(in oklab, var(--fallback-wa, oklch(var(--wa) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-wa, oklch(var(--wa) / 1)) 90%, black);
    }
  }

  .btn-outline.btn-error:hover {
    --tw-text-opacity: 1;
    color: var(--fallback-erc, oklch(var(--erc) / var(--tw-text-opacity)));
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .btn-outline.btn-error:hover {
      background-color: color-mix(in oklab, var(--fallback-er, oklch(var(--er) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-er, oklch(var(--er) / 1)) 90%, black);
    }
  }

  .btn-disabled:hover, .btn[disabled]:hover, .btn:disabled:hover {
    --tw-border-opacity: 0;
    background-color: var(--fallback-n, oklch(var(--n) / var(--tw-bg-opacity)));
    --tw-bg-opacity: .2;
    color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
    --tw-text-opacity: .2;
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .btn:is(input[type="checkbox"]:checked):hover, .btn:is(input[type="radio"]:checked):hover {
      background-color: color-mix(in oklab, var(--fallback-p, oklch(var(--p) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-p, oklch(var(--p) / 1)) 90%, black);
    }
  }

  .dropdown.dropdown-hover:hover .dropdown-content {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}

.dropdown:is(details) summary::-webkit-details-marker {
  display: none;
}

.file-input {
  border-radius: var(--rounded-btn, .5rem);
  border-width: 1px;
  border-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-border-opacity)));
  --tw-border-opacity: 0;
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity)));
  flex-shrink: 1;
  height: 3rem;
  padding-inline-end: 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
  overflow: hidden;
}

.file-input::file-selector-button {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  text-align: center;
  --tw-border-opacity: 1;
  border-style: solid;
  border-color: var(--fallback-n, oklch(var(--n) / var(--tw-border-opacity)));
  --tw-bg-opacity: 1;
  background-color: var(--fallback-n, oklch(var(--n) / var(--tw-bg-opacity)));
  text-transform: uppercase;
  --tw-text-opacity: 1;
  height: 100%;
  color: var(--fallback-nc, oklch(var(--nc) / var(--tw-text-opacity)));
  border-width: var(--border-btn, 1px);
  animation: button-pop var(--animation-btn, .25s) ease-out;
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin-inline-end: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1em;
  text-decoration-line: none;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  display: inline-flex;
}

.form-control {
  flex-direction: column;
  display: flex;
}

.label {
  -webkit-user-select: none;
  user-select: none;
  justify-content: space-between;
  align-items: center;
  padding: .5rem .25rem;
  display: flex;
}

.input {
  appearance: none;
  border-radius: var(--rounded-btn, .5rem);
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity)));
  border-width: 1px;
  border-color: #0000;
  flex-shrink: 1;
  height: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.input[type="number"]::-webkit-inner-spin-button {
  margin-top: -1rem;
  margin-bottom: -1rem;
  margin-inline-end: -1rem;
}

.input-md[type="number"]::-webkit-inner-spin-button {
  margin-top: -1rem;
  margin-bottom: -1rem;
  margin-inline-end: -1rem;
}

.join {
  border-radius: var(--rounded-btn, .5rem);
  align-items: stretch;
  display: inline-flex;
}

.join :where(.join-item), .join .join-item:not(:first-child):not(:last-child), .join :not(:first-child):not(:last-child) .join-item {
  border-start-start-radius: 0;
  border-start-end-radius: 0;
  border-end-end-radius: 0;
  border-end-start-radius: 0;
}

.join .join-item:first-child:not(:last-child), .join :first-child:not(:last-child) .join-item {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

.join .dropdown .join-item:first-child:not(:last-child), .join :first-child:not(:last-child) .dropdown .join-item {
  border-start-end-radius: inherit;
  border-end-end-radius: inherit;
}

.join :where(.join-item:first-child:not(:last-child)), .join :where(:first-child:not(:last-child) .join-item) {
  border-start-start-radius: inherit;
  border-end-start-radius: inherit;
}

.join .join-item:last-child:not(:first-child), .join :last-child:not(:first-child) .join-item {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

.join :where(.join-item:last-child:not(:first-child)), .join :where(:last-child:not(:first-child) .join-item) {
  border-start-end-radius: inherit;
  border-end-end-radius: inherit;
}

@supports not selector(:has(*)) {
  :where(.join *) {
    border-radius: inherit;
  }
}

@supports selector(:has(*)) {
  :where(.join :has(.join-item)) {
    border-radius: inherit;
  }
}

.link {
  cursor: pointer;
  text-decoration-line: underline;
}

.link-hover {
  text-decoration-line: none;
}

.menu li.disabled {
  cursor: not-allowed;
  -webkit-user-select: none;
  user-select: none;
  color: var(--fallback-bc, oklch(var(--bc) / .3));
}

.modal {
  pointer-events: none;
  opacity: 0;
  overscroll-behavior: contain;
  z-index: 999;
  width: 100%;
  max-width: none;
  height: 100%;
  max-height: none;
  color: inherit;
  background-color: #0000;
  justify-items: center;
  margin: 0;
  padding: 0;
  transition-property: transform, opacity, visibility;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  display: grid;
  position: fixed;
  inset: 0;
  overflow-y: hidden;
}

:where(.modal) {
  align-items: center;
}

.modal-box {
  --tw-scale-x: .9;
  --tw-scale-y: .9;
  width: 91.6667%;
  max-width: 32rem;
  max-height: calc(100vh - 5em);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-bottom-right-radius: var(--rounded-box, 1rem);
  border-bottom-left-radius: var(--rounded-box, 1rem);
  border-top-left-radius: var(--rounded-box, 1rem);
  border-top-right-radius: var(--rounded-box, 1rem);
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity)));
  overscroll-behavior: contain;
  grid-row-start: 1;
  grid-column-start: 1;
  padding: 1.5rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  overflow-y: auto;
  box-shadow: 0 25px 50px -12px #00000040;
}

.modal-open, .modal:target, .modal-toggle:checked + .modal, .modal[open] {
  pointer-events: auto;
  visibility: visible;
  opacity: 1;
}

.modal-action {
  justify-content: flex-end;
  margin-top: 1.5rem;
  display: flex;
}

:root:has(:is(.modal-open, .modal:target, .modal-toggle:checked + .modal, .modal[open])) {
  scrollbar-gutter: stable;
  overflow: hidden;
}

.progress {
  appearance: none;
  border-radius: var(--rounded-box, 1rem);
  background-color: var(--fallback-bc, oklch(var(--bc) / .2));
  width: 100%;
  height: .5rem;
  position: relative;
  overflow: hidden;
}

.radio {
  --chkbg: var(--bc);
  cursor: pointer;
  appearance: none;
  border-width: 1px;
  border-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-border-opacity)));
  --tw-border-opacity: .2;
  border-radius: 9999px;
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
}

.select {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  appearance: none;
  border-radius: var(--rounded-btn, .5rem);
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity)));
  background-image: linear-gradient(45deg, #0000 50%, currentColor 50%), linear-gradient(135deg, currentColor 50%, #0000 50%);
  background-position: calc(100% - 20px) calc(1px + 50%), calc(100% - 16.1px) calc(1px + 50%);
  background-repeat: no-repeat;
  background-size: 4px 4px, 4px 4px;
  border-width: 1px;
  border-color: #0000;
  height: 3rem;
  min-height: 3rem;
  padding-inline: 1rem 2.5rem;
  font-size: .875rem;
  line-height: 2;
  display: inline-flex;
}

.select[multiple] {
  height: auto;
}

.textarea {
  border-radius: var(--rounded-btn, .5rem);
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity)));
  border-width: 1px;
  border-color: #0000;
  flex-shrink: 1;
  min-height: 3rem;
  padding: .5rem 1rem;
  font-size: .875rem;
  line-height: 2;
}

.toast {
  white-space: nowrap;
  flex-direction: column;
  gap: .5rem;
  min-width: fit-content;
  padding: 1rem;
  display: flex;
  position: fixed;
}

.toggle {
  --tglbg: var(--fallback-b1, oklch(var(--b1) / 1));
  --handleoffset: 1.5rem;
  --handleoffsetcalculator: calc(var(--handleoffset) * -1);
  --togglehandleborder: 0 0;
  cursor: pointer;
  appearance: none;
  border-radius: var(--rounded-badge, 1.9rem);
  width: 3rem;
  height: 1.5rem;
  color: var(--fallback-bc, oklch(var(--bc) / .5));
  transition: background, box-shadow var(--animation-input, .2s) ease-out;
  box-shadow: var(--handleoffsetcalculator) 0 0 2px var(--tglbg) inset, 0 0 0 2px var(--tglbg) inset, var(--togglehandleborder);
  background-color: currentColor;
  border-width: 1px;
  border-color: currentColor;
  flex-shrink: 0;
}

.alert-error {
  border-color: var(--fallback-er, oklch(var(--er) / .2));
  --tw-text-opacity: 1;
  color: var(--fallback-erc, oklch(var(--erc) / var(--tw-text-opacity)));
  --alert-bg: var(--fallback-er, oklch(var(--er) / 1));
  --alert-bg-mix: var(--fallback-b1, oklch(var(--b1) / 1));
}

.btm-nav > :where(.active) {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity)));
  border-top-width: 2px;
}

.btm-nav > .disabled, .btm-nav > [disabled] {
  pointer-events: none;
  --tw-border-opacity: 0;
  background-color: var(--fallback-n, oklch(var(--n) / var(--tw-bg-opacity)));
  --tw-bg-opacity: .1;
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  --tw-text-opacity: .2;
}

.btm-nav > * .label {
  font-size: 1rem;
  line-height: 1.5rem;
}

@media (prefers-reduced-motion: no-preference) {
  .btn {
    animation: button-pop var(--animation-btn, .25s) ease-out;
  }
}

.btn:active:hover, .btn:active:focus {
  transform: scale(var(--btn-focus-scale, .97));
  animation: ease-out button-pop;
}

@supports not (color: oklch(0% 0 0)) {
  .btn {
    background-color: var(--btn-color, var(--fallback-b2));
    border-color: var(--btn-color, var(--fallback-b2));
  }

  .btn-primary {
    --btn-color: var(--fallback-p);
  }

  .btn-secondary {
    --btn-color: var(--fallback-s);
  }

  .btn-accent {
    --btn-color: var(--fallback-a);
  }

  .btn-info {
    --btn-color: var(--fallback-in);
  }

  .btn-success {
    --btn-color: var(--fallback-su);
  }

  .btn-warning {
    --btn-color: var(--fallback-wa);
  }

  .btn-error {
    --btn-color: var(--fallback-er);
  }
}

@supports (color: color-mix(in oklab, black, black)) {
  .btn-outline.btn-primary.btn-active {
    background-color: color-mix(in oklab, var(--fallback-p, oklch(var(--p) / 1)) 90%, black);
    border-color: color-mix(in oklab, var(--fallback-p, oklch(var(--p) / 1)) 90%, black);
  }

  .btn-outline.btn-secondary.btn-active {
    background-color: color-mix(in oklab, var(--fallback-s, oklch(var(--s) / 1)) 90%, black);
    border-color: color-mix(in oklab, var(--fallback-s, oklch(var(--s) / 1)) 90%, black);
  }

  .btn-outline.btn-accent.btn-active {
    background-color: color-mix(in oklab, var(--fallback-a, oklch(var(--a) / 1)) 90%, black);
    border-color: color-mix(in oklab, var(--fallback-a, oklch(var(--a) / 1)) 90%, black);
  }

  .btn-outline.btn-success.btn-active {
    background-color: color-mix(in oklab, var(--fallback-su, oklch(var(--su) / 1)) 90%, black);
    border-color: color-mix(in oklab, var(--fallback-su, oklch(var(--su) / 1)) 90%, black);
  }

  .btn-outline.btn-info.btn-active {
    background-color: color-mix(in oklab, var(--fallback-in, oklch(var(--in) / 1)) 90%, black);
    border-color: color-mix(in oklab, var(--fallback-in, oklch(var(--in) / 1)) 90%, black);
  }

  .btn-outline.btn-warning.btn-active {
    background-color: color-mix(in oklab, var(--fallback-wa, oklch(var(--wa) / 1)) 90%, black);
    border-color: color-mix(in oklab, var(--fallback-wa, oklch(var(--wa) / 1)) 90%, black);
  }

  .btn-outline.btn-error.btn-active {
    background-color: color-mix(in oklab, var(--fallback-er, oklch(var(--er) / 1)) 90%, black);
    border-color: color-mix(in oklab, var(--fallback-er, oklch(var(--er) / 1)) 90%, black);
  }
}

.btn:focus-visible {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
}

.btn-primary {
  --tw-text-opacity: 1;
  color: var(--fallback-pc, oklch(var(--pc) / var(--tw-text-opacity)));
  outline-color: var(--fallback-p, oklch(var(--p) / 1));
}

@supports (color: oklch(0% 0 0)) {
  .btn-primary {
    --btn-color: var(--p);
  }

  .btn-secondary {
    --btn-color: var(--s);
  }

  .btn-accent {
    --btn-color: var(--a);
  }

  .btn-info {
    --btn-color: var(--in);
  }

  .btn-success {
    --btn-color: var(--su);
  }

  .btn-warning {
    --btn-color: var(--wa);
  }

  .btn-error {
    --btn-color: var(--er);
  }
}

.btn-secondary {
  --tw-text-opacity: 1;
  color: var(--fallback-sc, oklch(var(--sc) / var(--tw-text-opacity)));
  outline-color: var(--fallback-s, oklch(var(--s) / 1));
}

.btn-accent {
  --tw-text-opacity: 1;
  color: var(--fallback-ac, oklch(var(--ac) / var(--tw-text-opacity)));
  outline-color: var(--fallback-a, oklch(var(--a) / 1));
}

.btn-info {
  --tw-text-opacity: 1;
  color: var(--fallback-inc, oklch(var(--inc) / var(--tw-text-opacity)));
  outline-color: var(--fallback-in, oklch(var(--in) / 1));
}

.btn-success {
  --tw-text-opacity: 1;
  color: var(--fallback-suc, oklch(var(--suc) / var(--tw-text-opacity)));
  outline-color: var(--fallback-su, oklch(var(--su) / 1));
}

.btn-warning {
  --tw-text-opacity: 1;
  color: var(--fallback-wac, oklch(var(--wac) / var(--tw-text-opacity)));
  outline-color: var(--fallback-wa, oklch(var(--wa) / 1));
}

.btn-error {
  --tw-text-opacity: 1;
  color: var(--fallback-erc, oklch(var(--erc) / var(--tw-text-opacity)));
  outline-color: var(--fallback-er, oklch(var(--er) / 1));
}

.btn.glass {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  outline-color: currentColor;
}

.btn.glass.btn-active {
  --glass-opacity: 25%;
  --glass-border-opacity: 15%;
}

.btn-ghost {
  color: currentColor;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  background-color: #0000;
  border-width: 1px;
  border-color: #0000;
  outline-color: currentColor;
}

.btn-ghost.btn-active {
  background-color: var(--fallback-bc, oklch(var(--bc) / .2));
  border-color: #0000;
}

.btn-outline {
  --tw-text-opacity: 1;
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  background-color: #0000;
  border-color: currentColor;
}

.btn-outline.btn-active {
  --tw-border-opacity: 1;
  border-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-border-opacity)));
  --tw-bg-opacity: 1;
  background-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-bg-opacity)));
  --tw-text-opacity: 1;
  color: var(--fallback-b1, oklch(var(--b1) / var(--tw-text-opacity)));
}

.btn-outline.btn-primary {
  --tw-text-opacity: 1;
  color: var(--fallback-p, oklch(var(--p) / var(--tw-text-opacity)));
}

.btn-outline.btn-primary.btn-active {
  --tw-text-opacity: 1;
  color: var(--fallback-pc, oklch(var(--pc) / var(--tw-text-opacity)));
}

.btn-outline.btn-secondary {
  --tw-text-opacity: 1;
  color: var(--fallback-s, oklch(var(--s) / var(--tw-text-opacity)));
}

.btn-outline.btn-secondary.btn-active {
  --tw-text-opacity: 1;
  color: var(--fallback-sc, oklch(var(--sc) / var(--tw-text-opacity)));
}

.btn-outline.btn-accent {
  --tw-text-opacity: 1;
  color: var(--fallback-a, oklch(var(--a) / var(--tw-text-opacity)));
}

.btn-outline.btn-accent.btn-active {
  --tw-text-opacity: 1;
  color: var(--fallback-ac, oklch(var(--ac) / var(--tw-text-opacity)));
}

.btn-outline.btn-success {
  --tw-text-opacity: 1;
  color: var(--fallback-su, oklch(var(--su) / var(--tw-text-opacity)));
}

.btn-outline.btn-success.btn-active {
  --tw-text-opacity: 1;
  color: var(--fallback-suc, oklch(var(--suc) / var(--tw-text-opacity)));
}

.btn-outline.btn-info {
  --tw-text-opacity: 1;
  color: var(--fallback-in, oklch(var(--in) / var(--tw-text-opacity)));
}

.btn-outline.btn-info.btn-active {
  --tw-text-opacity: 1;
  color: var(--fallback-inc, oklch(var(--inc) / var(--tw-text-opacity)));
}

.btn-outline.btn-warning {
  --tw-text-opacity: 1;
  color: var(--fallback-wa, oklch(var(--wa) / var(--tw-text-opacity)));
}

.btn-outline.btn-warning.btn-active {
  --tw-text-opacity: 1;
  color: var(--fallback-wac, oklch(var(--wac) / var(--tw-text-opacity)));
}

.btn-outline.btn-error {
  --tw-text-opacity: 1;
  color: var(--fallback-er, oklch(var(--er) / var(--tw-text-opacity)));
}

.btn-outline.btn-error.btn-active {
  --tw-text-opacity: 1;
  color: var(--fallback-erc, oklch(var(--erc) / var(--tw-text-opacity)));
}

.btn.btn-disabled, .btn[disabled], .btn:disabled {
  --tw-border-opacity: 0;
  background-color: var(--fallback-n, oklch(var(--n) / var(--tw-bg-opacity)));
  --tw-bg-opacity: .2;
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  --tw-text-opacity: .2;
}

.btn:is(input[type="checkbox"]:checked), .btn:is(input[type="radio"]:checked) {
  --tw-border-opacity: 1;
  border-color: var(--fallback-p, oklch(var(--p) / var(--tw-border-opacity)));
  --tw-bg-opacity: 1;
  background-color: var(--fallback-p, oklch(var(--p) / var(--tw-bg-opacity)));
  --tw-text-opacity: 1;
  color: var(--fallback-pc, oklch(var(--pc) / var(--tw-text-opacity)));
}

.btn:is(input[type="checkbox"]:checked):focus-visible, .btn:is(input[type="radio"]:checked):focus-visible {
  outline-color: var(--fallback-p, oklch(var(--p) / 1));
}

@keyframes button-pop {
  0% {
    transform: scale(var(--btn-focus-scale, .98));
  }

  40% {
    transform: scale(1.02);
  }

  100% {
    transform: scale(1);
  }
}

.card :where(figure:first-child) {
  border-start-start-radius: inherit;
  border-start-end-radius: inherit;
  border-end-end-radius: unset;
  border-end-start-radius: unset;
  overflow: hidden;
}

.card :where(figure:last-child) {
  border-start-start-radius: unset;
  border-start-end-radius: unset;
  border-end-end-radius: inherit;
  border-end-start-radius: inherit;
  overflow: hidden;
}

.card:focus-visible {
  outline-offset: 2px;
  outline: 2px solid;
}

.card.bordered {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-border-opacity)));
}

.card.compact .card-body {
  padding: 1rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.card-title {
  align-items: center;
  gap: .5rem;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.75rem;
  display: flex;
}

.card.image-full :where(figure) {
  border-radius: inherit;
  overflow: hidden;
}

.checkbox:focus {
  box-shadow: none;
}

.checkbox:focus-visible {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--fallback-bc, oklch(var(--bc) / 1));
}

.checkbox:disabled {
  cursor: not-allowed;
  --tw-bg-opacity: 1;
  background-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-bg-opacity)));
  opacity: .2;
  border-width: 0;
  border-color: #0000;
}

.checkbox:checked, .checkbox[aria-checked="true"] {
  animation: checkmark var(--animation-input, .2s) ease-out;
  background-repeat: no-repeat;
  background-color: var(--chkbg);
  background-image: linear-gradient(-45deg, transparent 65%, var(--chkbg) 65.99%), linear-gradient(45deg, transparent 75%, var(--chkbg) 75.99%), linear-gradient(-45deg, var(--chkbg) 40%, transparent 40.99%), linear-gradient(45deg, var(--chkbg) 30%, var(--chkfg) 30.99%, var(--chkfg) 40%, transparent 40.99%), linear-gradient(-45deg, var(--chkfg) 50%, var(--chkbg) 50.99%);
}

.checkbox:indeterminate {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-bg-opacity)));
  animation: checkmark var(--animation-input, .2s) ease-out;
  background-repeat: no-repeat;
  background-image: linear-gradient(90deg, transparent 80%, var(--chkbg) 80%), linear-gradient(-90deg, transparent 80%, var(--chkbg) 80%), linear-gradient(0deg, var(--chkbg) 43%, var(--chkfg) 43%, var(--chkfg) 57%, var(--chkbg) 57%);
}

@keyframes checkmark {
  0% {
    background-position-y: 5px;
  }

  50% {
    background-position-y: -2px;
  }

  100% {
    background-position-y: 0;
  }
}

details.collapse {
  width: 100%;
}

details.collapse summary {
  outline-offset: 2px;
  outline: 2px solid #0000;
  display: block;
  position: relative;
}

details.collapse summary::-webkit-details-marker {
  display: none;
}

.collapse:focus-visible, .collapse:has(.collapse-title:focus-visible), .collapse:has( > input[type="checkbox"]:focus-visible), .collapse:has( > input[type="radio"]:focus-visible) {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--fallback-bc, oklch(var(--bc) / 1));
}

.collapse:not(.collapse-open):not(.collapse-close) > input[type="checkbox"], .collapse:not(.collapse-open):not(.collapse-close) > input[type="radio"]:not(:checked), .collapse:not(.collapse-open):not(.collapse-close) > .collapse-title {
  cursor: pointer;
}

.collapse:focus:not(.collapse-open):not(.collapse-close):not(.collapse[open]) > .collapse-title {
  cursor: unset;
}

.collapse-title, :where(.collapse > input[type="checkbox"]), :where(.collapse > input[type="radio"]) {
  min-height: 3.75rem;
  padding: 1rem;
  padding-inline-end: 3rem;
  transition: background-color .2s ease-out;
}

.collapse[open] > :where(.collapse-content), .collapse-open > :where(.collapse-content), .collapse:focus:not(.collapse-close) > :where(.collapse-content), .collapse:not(.collapse-close) > :where(input[type="checkbox"]:checked ~ .collapse-content), .collapse:not(.collapse-close) > :where(input[type="radio"]:checked ~ .collapse-content) {
  padding-bottom: 1rem;
  transition: padding .2s ease-out, background-color .2s ease-out;
}

.collapse[open].collapse-arrow > .collapse-title:after, .collapse-open.collapse-arrow > .collapse-title:after, .collapse-arrow:focus:not(.collapse-close) > .collapse-title:after, .collapse-arrow:not(.collapse-close) > input[type="checkbox"]:checked ~ .collapse-title:after, .collapse-arrow:not(.collapse-close) > input[type="radio"]:checked ~ .collapse-title:after {
  --tw-translate-y: -50%;
  --tw-rotate: 225deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.collapse[open].collapse-plus > .collapse-title:after, .collapse-open.collapse-plus > .collapse-title:after, .collapse-plus:focus:not(.collapse-close) > .collapse-title:after, .collapse-plus:not(.collapse-close) > input[type="checkbox"]:checked ~ .collapse-title:after, .collapse-plus:not(.collapse-close) > input[type="radio"]:checked ~ .collapse-title:after {
  content: "−";
}

.dropdown.dropdown-open .dropdown-content, .dropdown:focus .dropdown-content, .dropdown:focus-within .dropdown-content {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.file-input:focus {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--fallback-bc, oklch(var(--bc) / .2));
}

.file-input-disabled, .file-input[disabled] {
  cursor: not-allowed;
  --tw-border-opacity: 1;
  border-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-border-opacity)));
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-bg-opacity)));
  --tw-text-opacity: .2;
}

.file-input-disabled::placeholder, .file-input[disabled]::placeholder {
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-placeholder-opacity)));
  --tw-placeholder-opacity: .2;
}

.file-input-disabled::file-selector-button {
  --tw-border-opacity: 0;
  background-color: var(--fallback-n, oklch(var(--n) / var(--tw-bg-opacity)));
  --tw-bg-opacity: .2;
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  --tw-text-opacity: .2;
}

.file-input[disabled]::file-selector-button {
  --tw-border-opacity: 0;
  background-color: var(--fallback-n, oklch(var(--n) / var(--tw-bg-opacity)));
  --tw-bg-opacity: .2;
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  --tw-text-opacity: .2;
}

.label-text {
  --tw-text-opacity: 1;
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  font-size: .875rem;
  line-height: 1.25rem;
}

.input input {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-p, oklch(var(--p) / var(--tw-bg-opacity)));
  background-color: #0000;
}

.input input:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.input[list]::-webkit-calendar-picker-indicator {
  line-height: 1em;
}

.input-bordered {
  border-color: var(--fallback-bc, oklch(var(--bc) / .2));
}

.input:focus, .input:focus-within {
  box-shadow: none;
  border-color: var(--fallback-bc, oklch(var(--bc) / .2));
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--fallback-bc, oklch(var(--bc) / .2));
}

.input-primary {
  --tw-border-opacity: 1;
  border-color: var(--fallback-p, oklch(var(--p) / var(--tw-border-opacity)));
}

.input-primary:focus, .input-primary:focus-within {
  --tw-border-opacity: 1;
  border-color: var(--fallback-p, oklch(var(--p) / var(--tw-border-opacity)));
  outline-color: var(--fallback-p, oklch(var(--p) / 1));
}

.input:has( > input[disabled]), .input-disabled, .input:disabled, .input[disabled] {
  cursor: not-allowed;
  --tw-border-opacity: 1;
  border-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-border-opacity)));
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-bg-opacity)));
  color: var(--fallback-bc, oklch(var(--bc) / .4));
}

.input:has( > input[disabled])::placeholder, .input-disabled::placeholder, .input:disabled::placeholder, .input[disabled]::placeholder {
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-placeholder-opacity)));
  --tw-placeholder-opacity: .2;
}

.input:has( > input[disabled]) > input[disabled] {
  cursor: not-allowed;
}

.input::-webkit-date-and-time-value {
  text-align: inherit;
}

.join > :where(:not(:first-child)) {
  margin-top: 0;
  margin-bottom: 0;
  margin-inline-start: -1px;
}

.join > :where(:not(:first-child)).btn {
  margin-inline-start: calc(var(--border-btn) * -1);
}

.join-item:focus {
  isolation: isolate;
}

@supports (color: color-mix(in oklab,black,black)) {
  @media (hover: hover) {
    .link-secondary:hover {
      color: color-mix(in oklab, var(--fallback-s, oklch(var(--s) / 1)) 80%, black);
    }
  }
}

.link-secondary {
  --tw-text-opacity: 1;
  color: var(--fallback-s, oklch(var(--s) / var(--tw-text-opacity)));
}

.link:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.link:focus-visible {
  outline-offset: 2px;
  outline: 2px solid;
}

.\!loading {
  pointer-events: none !important;
  aspect-ratio: 1 !important;
  background-color: currentColor !important;
  width: 1.5rem !important;
  display: inline-block !important;
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg width='24' height='24' stroke='black' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform-origin='center'%3E%3Ccircle cx='12' cy='12' r='9.5' fill='none' stroke-width='3' stroke-linecap='round'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 12 12' to='360 12 12' dur='2s' repeatCount='indefinite'/%3E%3Canimate attributeName='stroke-dasharray' values='0,150;42,150;42,150' keyTimes='0;0.475;1' dur='1.5s' repeatCount='indefinite'/%3E%3Canimate attributeName='stroke-dashoffset' values='0;-16;-59' keyTimes='0;0.475;1' dur='1.5s' repeatCount='indefinite'/%3E%3C/circle%3E%3C/g%3E%3C/svg%3E") !important;
  mask-image: url("data:image/svg+xml,%3Csvg width='24' height='24' stroke='black' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform-origin='center'%3E%3Ccircle cx='12' cy='12' r='9.5' fill='none' stroke-width='3' stroke-linecap='round'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 12 12' to='360 12 12' dur='2s' repeatCount='indefinite'/%3E%3Canimate attributeName='stroke-dasharray' values='0,150;42,150;42,150' keyTimes='0;0.475;1' dur='1.5s' repeatCount='indefinite'/%3E%3Canimate attributeName='stroke-dashoffset' values='0;-16;-59' keyTimes='0;0.475;1' dur='1.5s' repeatCount='indefinite'/%3E%3C/circle%3E%3C/g%3E%3C/svg%3E") !important;
  -webkit-mask-position: center !important;
  mask-position: center !important;
  -webkit-mask-size: 100% !important;
  mask-size: 100% !important;
  -webkit-mask-repeat: no-repeat !important;
  mask-repeat: no-repeat !important;
}

.loading {
  pointer-events: none;
  aspect-ratio: 1;
  background-color: currentColor;
  width: 1.5rem;
  display: inline-block;
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg width='24' height='24' stroke='black' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform-origin='center'%3E%3Ccircle cx='12' cy='12' r='9.5' fill='none' stroke-width='3' stroke-linecap='round'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 12 12' to='360 12 12' dur='2s' repeatCount='indefinite'/%3E%3Canimate attributeName='stroke-dasharray' values='0,150;42,150;42,150' keyTimes='0;0.475;1' dur='1.5s' repeatCount='indefinite'/%3E%3Canimate attributeName='stroke-dashoffset' values='0;-16;-59' keyTimes='0;0.475;1' dur='1.5s' repeatCount='indefinite'/%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg width='24' height='24' stroke='black' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform-origin='center'%3E%3Ccircle cx='12' cy='12' r='9.5' fill='none' stroke-width='3' stroke-linecap='round'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 12 12' to='360 12 12' dur='2s' repeatCount='indefinite'/%3E%3Canimate attributeName='stroke-dasharray' values='0,150;42,150;42,150' keyTimes='0;0.475;1' dur='1.5s' repeatCount='indefinite'/%3E%3Canimate attributeName='stroke-dashoffset' values='0;-16;-59' keyTimes='0;0.475;1' dur='1.5s' repeatCount='indefinite'/%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

:where(.menu li:not(.menu-title, .disabled) > :not(ul, details, .menu-title)):not(summary, .active, .btn).focus, :where(.menu li:not(.menu-title, .disabled) > :not(ul, details, .menu-title)):not(summary, .active, .btn):focus, :where(.menu li:not(.menu-title, .disabled) > :not(ul, details, .menu-title)):is(summary):not(.active, .btn):focus-visible, :where(.menu li:not(.menu-title, .disabled) > details > summary:not(.menu-title)):not(summary, .active, .btn).focus, :where(.menu li:not(.menu-title, .disabled) > details > summary:not(.menu-title)):not(summary, .active, .btn):focus, :where(.menu li:not(.menu-title, .disabled) > details > summary:not(.menu-title)):is(summary):not(.active, .btn):focus-visible {
  cursor: pointer;
  background-color: var(--fallback-bc, oklch(var(--bc) / .1));
  --tw-text-opacity: 1;
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.menu li > :not(ul, .menu-title, details, .btn):active, .menu li > :not(ul, .menu-title, details, .btn).active, .menu li > details > summary:active {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-n, oklch(var(--n) / var(--tw-bg-opacity)));
  --tw-text-opacity: 1;
  color: var(--fallback-nc, oklch(var(--nc) / var(--tw-text-opacity)));
}

.mockup-browser .mockup-browser-toolbar .input {
  text-overflow: ellipsis;
  white-space: nowrap;
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-bg-opacity)));
  direction: ltr;
  width: 24rem;
  height: 1.75rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  display: block;
  position: relative;
  overflow: hidden;
}

.mockup-browser .mockup-browser-toolbar .input:before {
  content: "";
  aspect-ratio: 1;
  --tw-translate-y: -50%;
  height: .75rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity: .6;
  border-width: 2px;
  border-color: currentColor;
  border-radius: 9999px;
  position: absolute;
  top: 50%;
  left: .5rem;
}

.mockup-browser .mockup-browser-toolbar .input:after {
  content: "";
  --tw-translate-y: 25%;
  --tw-rotate: -45deg;
  height: .5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity: .6;
  border-width: 1px;
  border-color: currentColor;
  border-radius: 9999px;
  position: absolute;
  top: 50%;
  left: 1.25rem;
}

.modal:not(dialog:not(.modal-open)), .modal::backdrop {
  background-color: #0006;
  animation: .2s ease-out modal-pop;
}

.modal-open .modal-box, .modal-toggle:checked + .modal .modal-box, .modal:target .modal-box, .modal[open] .modal-box {
  --tw-translate-y: 0px;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.modal-action > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.modal-action:where([dir="rtl"], [dir="rtl"] *) > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 1;
}

@keyframes modal-pop {
  0% {
    opacity: 0;
  }
}

.progress::-moz-progress-bar {
  border-radius: var(--rounded-box, 1rem);
  background-color: currentColor;
}

.progress-primary::-moz-progress-bar {
  border-radius: var(--rounded-box, 1rem);
  --tw-bg-opacity: 1;
  background-color: var(--fallback-p, oklch(var(--p) / var(--tw-bg-opacity)));
}

.progress:indeterminate {
  --progress-color: var(--fallback-bc, oklch(var(--bc) / 1));
  background-image: repeating-linear-gradient(90deg, var(--progress-color) -1%, var(--progress-color) 10%, transparent 10%, transparent 90%);
  background-position-x: 15%;
  background-size: 200%;
  animation: 5s ease-in-out infinite progress-loading;
}

.progress-primary:indeterminate {
  --progress-color: var(--fallback-p, oklch(var(--p) / 1));
}

.progress::-webkit-progress-bar {
  border-radius: var(--rounded-box, 1rem);
  background-color: #0000;
}

.progress::-webkit-progress-value {
  border-radius: var(--rounded-box, 1rem);
  background-color: currentColor;
}

.progress-primary::-webkit-progress-value {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-p, oklch(var(--p) / var(--tw-bg-opacity)));
}

.progress:indeterminate::-moz-progress-bar {
  background-color: #0000;
  background-image: repeating-linear-gradient(90deg, var(--progress-color) -1%, var(--progress-color) 10%, transparent 10%, transparent 90%);
  background-position-x: 15%;
  background-size: 200%;
  animation: 5s ease-in-out infinite progress-loading;
}

@keyframes progress-loading {
  50% {
    background-position-x: -115%;
  }
}

.radio:focus {
  box-shadow: none;
}

.radio:focus-visible {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--fallback-bc, oklch(var(--bc) / 1));
}

.radio:checked, .radio[aria-checked="true"] {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-bg-opacity)));
  animation: radiomark var(--animation-input, .2s) ease-out;
  box-shadow: 0 0 0 4px var(--fallback-b1, oklch(var(--b1) / 1)) inset, 0 0 0 4px var(--fallback-b1, oklch(var(--b1) / 1)) inset;
  background-image: none;
}

.radio:disabled {
  cursor: not-allowed;
  opacity: .2;
}

@keyframes radiomark {
  0% {
    box-shadow: 0 0 0 12px var(--fallback-b1, oklch(var(--b1) / 1)) inset, 0 0 0 12px var(--fallback-b1, oklch(var(--b1) / 1)) inset;
  }

  50% {
    box-shadow: 0 0 0 3px var(--fallback-b1, oklch(var(--b1) / 1)) inset, 0 0 0 3px var(--fallback-b1, oklch(var(--b1) / 1)) inset;
  }

  100% {
    box-shadow: 0 0 0 4px var(--fallback-b1, oklch(var(--b1) / 1)) inset, 0 0 0 4px var(--fallback-b1, oklch(var(--b1) / 1)) inset;
  }
}

@keyframes rating-pop {
  0% {
    transform: translateY(-.125em);
  }

  40% {
    transform: translateY(-.125em);
  }

  100% {
    transform: translateY(0);
  }
}

.select:focus {
  box-shadow: none;
  border-color: var(--fallback-bc, oklch(var(--bc) / .2));
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--fallback-bc, oklch(var(--bc) / .2));
}

.select-disabled, .select:disabled, .select[disabled] {
  cursor: not-allowed;
  --tw-border-opacity: 1;
  border-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-border-opacity)));
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-bg-opacity)));
  color: var(--fallback-bc, oklch(var(--bc) / .4));
}

.select-disabled::placeholder, .select:disabled::placeholder, .select[disabled]::placeholder {
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-placeholder-opacity)));
  --tw-placeholder-opacity: .2;
}

.select-multiple, .select[multiple], .select[size].select:not([size="1"]) {
  background-image: none;
  padding-right: 1rem;
}

[dir="rtl"] .select {
  background-position: 12px calc(1px + 50%), 16px calc(1px + 50%);
}

@keyframes skeleton {
  from {
    background-position: 150%;
  }

  to {
    background-position: -50%;
  }
}

.table tr.active, .table tr.active:nth-child(2n), .table-zebra tbody tr:nth-child(2n) {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-bg-opacity)));
}

.table-zebra tr.active, .table-zebra tr.active:nth-child(2n), .table-zebra-zebra tbody tr:nth-child(2n) {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b3, oklch(var(--b3) / var(--tw-bg-opacity)));
}

.textarea:focus {
  box-shadow: none;
  border-color: var(--fallback-bc, oklch(var(--bc) / .2));
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--fallback-bc, oklch(var(--bc) / .2));
}

.textarea-primary {
  --tw-border-opacity: 1;
  border-color: var(--fallback-p, oklch(var(--p) / var(--tw-border-opacity)));
}

.textarea-primary:focus {
  --tw-border-opacity: 1;
  border-color: var(--fallback-p, oklch(var(--p) / var(--tw-border-opacity)));
  outline-color: var(--fallback-p, oklch(var(--p) / 1));
}

.textarea-disabled, .textarea:disabled, .textarea[disabled] {
  cursor: not-allowed;
  --tw-border-opacity: 1;
  border-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-border-opacity)));
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-bg-opacity)));
  color: var(--fallback-bc, oklch(var(--bc) / .4));
}

.textarea-disabled::placeholder, .textarea:disabled::placeholder, .textarea[disabled]::placeholder {
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-placeholder-opacity)));
  --tw-placeholder-opacity: .2;
}

.toast > * {
  animation: .25s ease-out toast-pop;
}

@keyframes toast-pop {
  0% {
    opacity: 0;
    transform: scale(.9);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

[dir="rtl"] .toggle {
  --handleoffsetcalculator: calc(var(--handleoffset) * 1);
}

.toggle:focus-visible {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--fallback-bc, oklch(var(--bc) / .2));
}

.toggle:hover {
  background-color: currentColor;
}

.toggle:checked, .toggle[aria-checked="true"] {
  --handleoffsetcalculator: var(--handleoffset);
  --tw-text-opacity: 1;
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  background-image: none;
}

[dir="rtl"] .toggle:checked, [dir="rtl"] .toggle[aria-checked="true"] {
  --handleoffsetcalculator: calc(var(--handleoffset) * -1);
}

.toggle:indeterminate {
  --tw-text-opacity: 1;
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  box-shadow: calc(var(--handleoffset) / 2) 0 0 2px var(--tglbg) inset, calc(var(--handleoffset) / -2) 0 0 2px var(--tglbg) inset, 0 0 0 2px var(--tglbg) inset;
}

[dir="rtl"] .toggle:indeterminate {
  box-shadow: calc(var(--handleoffset) / 2) 0 0 2px var(--tglbg) inset, calc(var(--handleoffset) / -2) 0 0 2px var(--tglbg) inset, 0 0 0 2px var(--tglbg) inset;
}

.toggle-primary:focus-visible {
  outline-color: var(--fallback-p, oklch(var(--p) / 1));
}

.toggle-primary:checked, .toggle-primary[aria-checked="true"] {
  border-color: var(--fallback-p, oklch(var(--p) / var(--tw-border-opacity)));
  --tw-border-opacity: .1;
  --tw-bg-opacity: 1;
  background-color: var(--fallback-p, oklch(var(--p) / var(--tw-bg-opacity)));
  --tw-text-opacity: 1;
  color: var(--fallback-pc, oklch(var(--pc) / var(--tw-text-opacity)));
}

.toggle-secondary:focus-visible {
  outline-color: var(--fallback-s, oklch(var(--s) / 1));
}

.toggle-secondary:checked, .toggle-secondary[aria-checked="true"] {
  border-color: var(--fallback-s, oklch(var(--s) / var(--tw-border-opacity)));
  --tw-border-opacity: .1;
  --tw-bg-opacity: 1;
  background-color: var(--fallback-s, oklch(var(--s) / var(--tw-bg-opacity)));
  --tw-text-opacity: 1;
  color: var(--fallback-sc, oklch(var(--sc) / var(--tw-text-opacity)));
}

.toggle-accent:focus-visible {
  outline-color: var(--fallback-a, oklch(var(--a) / 1));
}

.toggle-accent:checked, .toggle-accent[aria-checked="true"] {
  border-color: var(--fallback-a, oklch(var(--a) / var(--tw-border-opacity)));
  --tw-border-opacity: .1;
  --tw-bg-opacity: 1;
  background-color: var(--fallback-a, oklch(var(--a) / var(--tw-bg-opacity)));
  --tw-text-opacity: 1;
  color: var(--fallback-ac, oklch(var(--ac) / var(--tw-text-opacity)));
}

.toggle:disabled {
  cursor: not-allowed;
  --tw-border-opacity: 1;
  border-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-border-opacity)));
  opacity: .3;
  --togglehandleborder: 0 0 0 3px var(--fallback-bc, oklch(var(--bc) / 1)) inset, var(--handleoffsetcalculator) 0 0 3px var(--fallback-bc, oklch(var(--bc) / 1)) inset;
  background-color: #0000;
}

.btm-nav-xs > :where(.active) {
  border-top-width: 1px;
}

.btm-nav-sm > :where(.active), .btm-nav-md > :where(.active) {
  border-top-width: 2px;
}

.btm-nav-lg > :where(.active) {
  border-top-width: 4px;
}

.btn-xs {
  height: 1.5rem;
  min-height: 1.5rem;
  padding-left: .5rem;
  padding-right: .5rem;
  font-size: .75rem;
}

.btn-sm {
  height: 2rem;
  min-height: 2rem;
  padding-left: .75rem;
  padding-right: .75rem;
  font-size: .875rem;
}

.btn-md {
  height: 3rem;
  min-height: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: .875rem;
}

.btn-lg {
  height: 4rem;
  min-height: 4rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-size: 1.125rem;
}

.btn-block {
  width: 100%;
}

.btn-square:where(.btn-xs) {
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
}

.btn-square:where(.btn-sm) {
  width: 2rem;
  height: 2rem;
  padding: 0;
}

.btn-square:where(.btn-md) {
  width: 3rem;
  height: 3rem;
  padding: 0;
}

.btn-square:where(.btn-lg) {
  width: 4rem;
  height: 4rem;
  padding: 0;
}

.btn-circle:where(.btn-xs) {
  border-radius: 9999px;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
}

.btn-circle:where(.btn-sm) {
  border-radius: 9999px;
  width: 2rem;
  height: 2rem;
  padding: 0;
}

.btn-circle:where(.btn-md) {
  border-radius: 9999px;
  width: 3rem;
  height: 3rem;
  padding: 0;
}

.btn-circle:where(.btn-lg) {
  border-radius: 9999px;
  width: 4rem;
  height: 4rem;
  padding: 0;
}

.join.join-vertical {
  flex-direction: column;
}

.join.join-vertical .join-item:first-child:not(:last-child), .join.join-vertical :first-child:not(:last-child) .join-item {
  border-start-start-radius: inherit;
  border-start-end-radius: inherit;
  border-end-end-radius: 0;
  border-end-start-radius: 0;
}

.join.join-vertical .join-item:last-child:not(:first-child), .join.join-vertical :last-child:not(:first-child) .join-item {
  border-start-start-radius: 0;
  border-start-end-radius: 0;
  border-end-end-radius: inherit;
  border-end-start-radius: inherit;
}

.join.join-horizontal {
  flex-direction: row;
}

.join.join-horizontal .join-item:first-child:not(:last-child), .join.join-horizontal :first-child:not(:last-child) .join-item {
  border-start-start-radius: inherit;
  border-start-end-radius: 0;
  border-end-end-radius: 0;
  border-end-start-radius: inherit;
}

.join.join-horizontal .join-item:last-child:not(:first-child), .join.join-horizontal :last-child:not(:first-child) .join-item {
  border-start-start-radius: 0;
  border-start-end-radius: inherit;
  border-end-end-radius: inherit;
  border-end-start-radius: 0;
}

:where(.toast) {
  bottom: 0;
  --tw-translate-x: 0px;
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  inset-inline: auto 0;
  top: auto;
}

.toast:where(.toast-start) {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  inset-inline: 0 auto;
}

.toast:where(.toast-center) {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  inset-inline: 50%;
}

.toast:where(.toast-center):where([dir="rtl"], [dir="rtl"] *) {
  --tw-translate-x: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.toast:where(.toast-end) {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  inset-inline: auto 0;
}

.toast:where(.toast-bottom) {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  top: auto;
  bottom: 0;
}

.toast:where(.toast-middle) {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  top: 50%;
  bottom: auto;
}

.toast:where(.toast-top) {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  top: 0;
  bottom: auto;
}

[type="checkbox"].toggle-xs {
  --handleoffset: .5rem;
  width: 1.5rem;
  height: 1rem;
}

[type="checkbox"].toggle-sm {
  --handleoffset: .75rem;
  width: 2rem;
  height: 1.25rem;
}

[type="checkbox"].toggle-md {
  --handleoffset: 1.5rem;
  width: 3rem;
  height: 1.5rem;
}

[type="checkbox"].toggle-lg {
  --handleoffset: 2rem;
  width: 4rem;
  height: 2rem;
}

.tooltip {
  --tooltip-offset: calc(100% + 1px + var(--tooltip-tail, 0px));
  display: inline-block;
  position: relative;
}

.tooltip:before {
  pointer-events: none;
  z-index: 1;
  content: var(--tw-content);
  --tw-content: attr(data-tip);
  position: absolute;
}

.tooltip:before, .tooltip-top:before {
  top: auto;
  left: 50%;
  right: auto;
  bottom: var(--tooltip-offset);
  transform: translateX(-50%);
}

.card-compact .card-title {
  margin-bottom: .25rem;
}

.card-normal .card-title {
  margin-bottom: .75rem;
}

.join.join-vertical > :where(:not(:first-child)) {
  margin-top: -1px;
  margin-left: 0;
  margin-right: 0;
}

.join.join-vertical > :where(:not(:first-child)).btn {
  margin-top: calc(var(--border-btn) * -1);
}

.join.join-horizontal > :where(:not(:first-child)) {
  margin-top: 0;
  margin-bottom: 0;
  margin-inline-start: -1px;
}

.join.join-horizontal > :where(:not(:first-child)).btn {
  margin-inline-start: calc(var(--border-btn) * -1);
  margin-top: 0;
}

.modal-top :where(.modal-box) {
  --tw-translate-y: -2.5rem;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  width: 100%;
  max-width: none;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-bottom-right-radius: var(--rounded-box, 1rem);
  border-bottom-left-radius: var(--rounded-box, 1rem);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.modal-middle :where(.modal-box) {
  --tw-translate-y: 0px;
  --tw-scale-x: .9;
  --tw-scale-y: .9;
  width: 91.6667%;
  max-width: 32rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-top-left-radius: var(--rounded-box, 1rem);
  border-top-right-radius: var(--rounded-box, 1rem);
  border-bottom-right-radius: var(--rounded-box, 1rem);
  border-bottom-left-radius: var(--rounded-box, 1rem);
}

.modal-bottom :where(.modal-box) {
  --tw-translate-y: 2.5rem;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  width: 100%;
  max-width: none;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-top-left-radius: var(--rounded-box, 1rem);
  border-top-right-radius: var(--rounded-box, 1rem);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.tooltip {
  text-align: center;
  --tooltip-tail: .1875rem;
  --tooltip-color: var(--fallback-n, oklch(var(--n) / 1));
  --tooltip-text-color: var(--fallback-nc, oklch(var(--nc) / 1));
  --tooltip-tail-offset: calc(100% + .0625rem - var(--tooltip-tail));
  display: inline-block;
  position: relative;
}

.tooltip:before, .tooltip:after {
  opacity: 0;
  transition: color .2s cubic-bezier(.4, 0, .2, 1) .1s, background-color .2s cubic-bezier(.4, 0, .2, 1) .1s, border-color .2s cubic-bezier(.4, 0, .2, 1) .1s, text-decoration-color .2s cubic-bezier(.4, 0, .2, 1) .1s, fill .2s cubic-bezier(.4, 0, .2, 1) .1s, stroke .2s cubic-bezier(.4, 0, .2, 1) .1s, opacity .2s cubic-bezier(.4, 0, .2, 1) .1s, box-shadow .2s cubic-bezier(.4, 0, .2, 1) .1s, transform .2s cubic-bezier(.4, 0, .2, 1) .1s, filter .2s cubic-bezier(.4, 0, .2, 1) .1s, -webkit-backdrop-filter .2s cubic-bezier(.4, 0, .2, 1) .1s, backdrop-filter .2s cubic-bezier(.4, 0, .2, 1) .1s;
}

.tooltip:after {
  content: "";
  border-style: solid;
  border-width: var(--tooltip-tail, 0);
  width: 0;
  height: 0;
  display: block;
  position: absolute;
}

.tooltip:before {
  white-space: normal;
  background-color: var(--tooltip-color);
  max-width: 20rem;
  color: var(--tooltip-text-color);
  border-radius: .25rem;
  width: max-content;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.tooltip.tooltip-open:before, .tooltip.tooltip-open:after, .tooltip:hover:before, .tooltip:hover:after, .tooltip:has(:focus-visible):after, .tooltip:has(:focus-visible):before {
  opacity: 1;
  transition-delay: 75ms;
}

.tooltip:not([data-tip]):hover:before, .tooltip:not([data-tip]):hover:after {
  visibility: hidden;
  opacity: 0;
}

.tooltip:after, .tooltip-top:after {
  border-color: var(--tooltip-color) transparent transparent transparent;
  top: auto;
  left: 50%;
  right: auto;
  bottom: var(--tooltip-tail-offset);
  transform: translateX(-50%);
}

.dropdown .dropdown-content {
  z-index: 2000 !important;
}

.toggle[data-state="checked"] {
  --chkbg: oklch(var(--bc));
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  box-shadow: var(--handleoffset) 0 0 2px oklch(var(--b1)) inset, 0 0 0 2px oklch(var(--b1)) inset;
}

[dir="rtl"] .toggle[data-state="checked"] {
  box-shadow: calc(var(--handleoffset) * 1) 0 0 2px oklch(var(--b1)) inset, 0 0 0 2px oklch(var(--b1)) inset;
}

.toggle-primary[data-state="checked"] {
  --chkbg: oklch(var(--p));
  border-color: var(--fallback-p, oklch(var(--p) / var(--tw-border-opacity, 1)));
  --tw-border-opacity: .1;
  --tw-bg-opacity: 1;
  background-color: var(--fallback-p, oklch(var(--p) / var(--tw-bg-opacity, 1)));
  --tw-text-opacity: 1;
  color: var(--fallback-pc, oklch(var(--pc) / var(--tw-text-opacity, 1)));
}

.toggle-secondary[data-state="checked"] {
  --chkbg: oklch(var(--s));
  border-color: var(--fallback-s, oklch(var(--s) / var(--tw-border-opacity, 1)));
  --tw-border-opacity: .1;
  --tw-bg-opacity: 1;
  background-color: var(--fallback-s, oklch(var(--s) / var(--tw-bg-opacity, 1)));
  --tw-text-opacity: 1;
  color: var(--fallback-sc, oklch(var(--sc) / var(--tw-text-opacity, 1)));
}

.toggle-accent[data-state="checked"] {
  --chkbg: oklch(var(--a));
  border-color: var(--fallback-a, oklch(var(--a) / var(--tw-border-opacity, 1)));
  --tw-border-opacity: .1;
  --tw-bg-opacity: 1;
  background-color: var(--fallback-a, oklch(var(--a) / var(--tw-bg-opacity, 1)));
  --tw-text-opacity: 1;
  color: var(--fallback-ac, oklch(var(--ac) / var(--tw-text-opacity, 1)));
}

.toggle:disabled {
  cursor: not-allowed;
  background-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-bg-opacity, 1)));
  --tw-bg-opacity: .2;
  border-color: #0000;
}

.toggle-xs {
  --handleoffset: .5rem;
  width: 1.5rem;
  height: 1rem;
}

.toggle-sm {
  --handleoffset: .75rem;
  width: 2rem;
  height: 1.25rem;
}

.toggle-md {
  --handleoffset: 1.5rem;
  width: 3rem;
  height: 1.5rem;
}

.toggle-lg {
  --handleoffset: 2rem;
  width: 4rem;
  height: 2rem;
}

.btn {
  height: auto;
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.visible {
  visibility: visible;
}

.collapse {
  visibility: collapse;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.right-1\/2 {
  right: 50%;
}

.right-4 {
  right: 1rem;
}

.top-0 {
  top: 0;
}

.top-1\/2 {
  top: 50%;
}

.top-4 {
  top: 1rem;
}

.z-50 {
  z-index: 50;
}

.z-editor-dialog {
  z-index: 101;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.m-4 {
  margin: 1rem;
}

.\!my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.aspect-square {
  aspect-ratio: 1;
}

.h-12 {
  height: 3rem;
}

.h-20 {
  height: 5rem;
}

.h-32 {
  height: 8rem;
}

.h-6 {
  height: 1.5rem;
}

.h-8 {
  height: 2rem;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.max-h-64 {
  max-height: 16rem;
}

.max-h-screen {
  max-height: 100vh;
}

.min-h-screen {
  min-height: 100vh;
}

.w-11\/12 {
  width: 91.6667%;
}

.w-3\/4 {
  width: 75%;
}

.w-32 {
  width: 8rem;
}

.w-4\/5 {
  width: 80%;
}

.w-8 {
  width: 2rem;
}

.w-auto {
  width: auto;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-screen-xl {
  max-width: 1280px;
}

.max-w-xs {
  max-width: 20rem;
}

.flex-1 {
  flex: 1;
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-1\/2 {
  --tw-translate-x: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.resize {
  resize: both;
}

.appearance-none {
  appearance: none;
}

.grid-flow-col {
  grid-auto-flow: column;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.place-items-center {
  place-items: center;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.gap-1\.5 {
  gap: .375rem;
}

.gap-2 {
  gap: .5rem;
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.25rem * var(--tw-space-x-reverse));
  margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded {
  border-radius: .25rem;
}

.rounded-btn {
  border-radius: var(--rounded-btn, .5rem);
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-md {
  border-radius: .375rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-t-2 {
  border-top-width: 2px;
}

.border-dotted {
  border-style: dotted;
}

.border-base-content {
  --tw-border-opacity: 1;
  border-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-border-opacity, 1)));
}

.border-gray-900 {
  --tw-border-opacity: 1;
  border-color: rgb(17 24 39 / var(--tw-border-opacity, 1));
}

.border-neutral {
  --tw-border-opacity: 1;
  border-color: var(--fallback-n, oklch(var(--n) / var(--tw-border-opacity, 1)));
}

.border-transparent {
  border-color: #0000;
}

.bg-base-100 {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity, 1)));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
}

.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity, 1));
}

.bg-neutral {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-n, oklch(var(--n) / var(--tw-bg-opacity, 1)));
}

.bg-red-mylim {
  --tw-bg-opacity: 1;
  background-color: rgb(236 93 87 / var(--tw-bg-opacity, 1));
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: .25rem;
}

.p-2 {
  padding: .5rem;
}

.p-6 {
  padding: 1.5rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pt-2 {
  padding-top: .5rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.align-baseline {
  vertical-align: baseline;
}

.align-middle {
  vertical-align: middle;
}

.font-sans {
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.font-serif {
  font-family: ui-serif, Georgia, Cambria, Times New Roman, Times, serif;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.normal-case {
  text-transform: none;
}

.italic {
  font-style: italic;
}

.leading-none {
  line-height: 1;
}

.tracking-wider {
  letter-spacing: .05em;
}

.text-base-content {
  --tw-text-opacity: 1;
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity, 1)));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity, 1));
}

.text-neutral {
  --tw-text-opacity: 1;
  color: var(--fallback-n, oklch(var(--n) / var(--tw-text-opacity, 1)));
}

.text-neutral-content {
  --tw-text-opacity: 1;
  color: var(--fallback-nc, oklch(var(--nc) / var(--tw-text-opacity, 1)));
}

.text-red-mylim {
  --tw-text-opacity: 1;
  color: rgb(236 93 87 / var(--tw-text-opacity, 1));
}

.text-warning {
  --tw-text-opacity: 1;
  color: var(--fallback-wa, oklch(var(--wa) / var(--tw-text-opacity, 1)));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.underline {
  text-decoration-line: underline;
}

.underline-offset-2 {
  text-underline-offset: 2px;
}

.placeholder-gray-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(156 163 175 / var(--tw-placeholder-opacity, 1));
}

.opacity-50 {
  opacity: .5;
}

.opacity-70 {
  opacity: .7;
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline {
  outline-style: solid;
}

.outline-2 {
  outline-width: 2px;
}

.outline-offset-2 {
  outline-offset: 2px;
}

.outline-\[oklch\(var\(--btn-color\,_var\(--b2\)\)_\/_\<alpha-value\>\)\] {
  outline-color: oklch(var(--btn-color, var(--b2)) / < alpha-value> );
}

.outline-accent {
  outline-color: var(--fallback-a, oklch(var(--a) / 1));
}

.outline-base-content {
  outline-color: var(--fallback-bc, oklch(var(--bc) / 1));
}

.outline-primary {
  outline-color: var(--fallback-p, oklch(var(--p) / 1));
}

.outline-secondary {
  outline-color: var(--fallback-s, oklch(var(--s) / 1));
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.font-al {
  font-family: Biancoenero, sans-serif;
}

.topbar {
  z-index: 20;
  width: 100%;
  min-height: 4rem;
  padding: var(--navbar-padding, .5rem);
  grid-template-columns: 1fr minmax(min-content, max-content) 1fr;
  gap: .25rem;
  display: grid;
  position: sticky;
  top: 0;
}

.topbar-start {
  justify-self: start;
}

.topbar-end {
  justify-self: end;
}

:where(.topbar > :not(script, style)) {
  align-items: center;
  display: inline-flex;
}

html[data-no-sticky] .topbar {
  position: static;
}

.toolbar {
  z-index: 10;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0;
  display: flex;
  position: sticky;
}

@media (width >= 640px) {
  .toolbar {
    flex-wrap: nowrap;
  }
}

.toolbar {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity, 1)));
  --tw-text-opacity: 1;
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity, 1)));
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  min-height: var(--toolbar-height);
  top: var(--top, 0);
}

html[data-no-sticky] .toolbar {
  position: relative;
  top: 0;
}

.toolbar__left, .toolbar__right {
  flex: 1;
}

.toolbar__center {
  flex: 0 0 100%;
  max-width: 640px;
}

@media (width >= 640px) {
  .toolbar__center {
    flex: 4;
  }
}

.editor-toolbar {
  z-index: 20;
  --tw-translate-x: -50%;
  width: min-content;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-radius: var(--rounded-box, 1rem);
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity, 1));
  justify-content: center;
  padding: .5rem;
  display: flex;
  position: fixed;
  left: 50%;
}

.editor-toolbar > * {
  margin-left: .25rem;
  margin-right: .25rem;
}

.editing-toolbar {
  --tw-translate-x: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-border-opacity: 1;
  border-color: var(--fallback-p, oklch(var(--p) / var(--tw-border-opacity, 1)));
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity, 1)));
  border-bottom-left-radius: .375rem;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  min-height: 3rem;
  display: flex;
  position: absolute;
  bottom: 0;
  right: 50%;
}

.editing-toolbar > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.editing-toolbar {
  padding: .5rem;
}

@media (width >= 640px) {
  .editing-toolbar {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    flex-direction: column;
    align-items: flex-start;
    top: 100%;
    right: 0;
  }

  .editing-toolbar > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
    --tw-space-y-reverse: 0;
    margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
  }

  .editing-toolbar {
    bottom: unset;
  }
}

.book-container {
  width: 100%;
  margin-block-start: 5.5rem;
  padding-bottom: 8rem;
  padding-left: 1rem;
  padding-right: 1rem;
  overflow-x: hidden;
}

html:not([data-no-sticky]) .book-container {
  scroll-margin-block-start: var(--total-header, 0);
}

.editor-contents {
  color: var(--tw-prose-body);
  max-width: 65ch;
}

.editor-contents :where([class~="lead"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-lead);
  margin-top: 1.2em;
  margin-bottom: 1.2em;
  font-size: 1.25em;
  line-height: 1.6;
}

.editor-contents :where(a):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-links);
  font-weight: 500;
  text-decoration: underline;
}

.editor-contents :where(strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-bold);
  font-weight: 600;
}

.editor-contents :where(a strong):not(:where([class~="not-prose"], [class~="not-prose"] *)), .editor-contents :where(blockquote strong):not(:where([class~="not-prose"], [class~="not-prose"] *)), .editor-contents :where(thead th strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
}

.editor-contents :where(ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-inline-start: 1.625em;
  list-style-type: decimal;
}

.editor-contents :where(ol[type="A"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

.editor-contents :where(ol[type="a"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

.editor-contents :where(ol[type="A" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

.editor-contents :where(ol[type="a" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

.editor-contents :where(ol[type="I"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

.editor-contents :where(ol[type="i"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

.editor-contents :where(ol[type="I" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

.editor-contents :where(ol[type="i" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

.editor-contents :where(ol[type="1"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: decimal;
}

.editor-contents :where(ul):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-inline-start: 1.625em;
  list-style-type: disc;
}

.editor-contents :where(ol > li):not(:where([class~="not-prose"], [class~="not-prose"] *))::marker {
  color: var(--tw-prose-counters);
  font-weight: 400;
}

.editor-contents :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *))::marker {
  color: var(--tw-prose-bullets);
}

.editor-contents :where(dt):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.25em;
  font-weight: 600;
}

.editor-contents :where(hr):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-color: var(--tw-prose-hr);
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}

.editor-contents :where(blockquote):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-quotes);
  border-inline-start-width: .25rem;
  border-inline-start-color: var(--tw-prose-quote-borders);
  quotes: "“""”""‘""’";
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-inline-start: 1em;
  font-style: normal;
  font-weight: 500;
}

.editor-contents :where(blockquote p:first-of-type):not(:where([class~="not-prose"], [class~="not-prose"] *)):before, .editor-contents :where(blockquote p:last-of-type):not(:where([class~="not-prose"], [class~="not-prose"] *)):after {
  content: ;
}

.editor-contents :where(h1):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 0;
  margin-bottom: .888889em;
  font-size: 2.25em;
  font-weight: 800;
  line-height: 1.11111;
}

.editor-contents :where(h1 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-weight: 900;
}

.editor-contents :where(h2):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.33333;
}

.editor-contents :where(h2 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-weight: 800;
}

.editor-contents :where(h3):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.6em;
  margin-bottom: .6em;
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.6;
}

.editor-contents :where(h3 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-weight: 700;
}

.editor-contents :where(h4):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.5em;
  margin-bottom: .5em;
  font-weight: 600;
  line-height: 1.5;
}

.editor-contents :where(h4 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-weight: 700;
}

.editor-contents :where(picture):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
  display: block;
}

.editor-contents :where(kbd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-kbd);
  box-shadow: 0 0 0 1px rgb(var(--tw-prose-kbd-shadows) / 10%), 0 3px 0 rgb(var(--tw-prose-kbd-shadows) / 10%);
  padding-top: .1875em;
  padding-inline-end: .375em;
  padding-bottom: .1875em;
  border-radius: .3125rem;
  padding-inline-start: .375em;
  font-family: inherit;
  font-size: .875em;
  font-weight: 500;
}

.editor-contents :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-code);
  font-size: .875em;
  font-weight: 600;
}

.editor-contents :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)):before, .editor-contents :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)):after {
  content: "`";
}

.editor-contents :where(a code):not(:where([class~="not-prose"], [class~="not-prose"] *)), .editor-contents :where(h1 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
}

.editor-contents :where(h2 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-size: .875em;
}

.editor-contents :where(h3 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-size: .9em;
}

.editor-contents :where(h4 code):not(:where([class~="not-prose"], [class~="not-prose"] *)), .editor-contents :where(blockquote code):not(:where([class~="not-prose"], [class~="not-prose"] *)), .editor-contents :where(thead th code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
}

.editor-contents :where(pre):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-pre-code);
  background-color: var(--tw-prose-pre-bg);
  padding-top: .857143em;
  padding-inline-end: 1.14286em;
  padding-bottom: .857143em;
  border-radius: .375rem;
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
  padding-inline-start: 1.14286em;
  font-size: .875em;
  font-weight: 400;
  line-height: 1.71429;
  overflow-x: auto;
}

.editor-contents :where(pre code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  background-color: #0000;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

.editor-contents :where(pre code):not(:where([class~="not-prose"], [class~="not-prose"] *)):before, .editor-contents :where(pre code):not(:where([class~="not-prose"], [class~="not-prose"] *)):after {
  content: none;
}

.editor-contents :where(table):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  table-layout: auto;
  width: 100%;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: .875em;
  line-height: 1.71429;
}

.editor-contents :where(thead):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-th-borders);
}

.editor-contents :where(thead th):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  vertical-align: bottom;
  padding-inline-end: .571429em;
  padding-bottom: .571429em;
  padding-inline-start: .571429em;
  font-weight: 600;
}

.editor-contents :where(tbody tr):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-td-borders);
}

.editor-contents :where(tbody tr:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-bottom-width: 0;
}

.editor-contents :where(tbody td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  vertical-align: baseline;
}

.editor-contents :where(tfoot):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-top-width: 1px;
  border-top-color: var(--tw-prose-th-borders);
}

.editor-contents :where(tfoot td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  vertical-align: top;
}

.editor-contents :where(th, td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  text-align: start;
}

.editor-contents :where(figcaption):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  margin-top: .857143em;
  font-size: .875em;
  line-height: 1.42857;
}

.editor-contents {
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-kbd: #111827;
  --tw-prose-kbd-shadows: 17 24 39;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-kbd: #fff;
  --tw-prose-invert-kbd-shadows: 255 255 255;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: #00000080;
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
  font-size: 1rem;
  line-height: calc(var(--line-height-multiplier) * 1.75);
}

.editor-contents :where(.prose > ul > li p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.editor-contents :where(.prose > ul > li > p:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.editor-contents :where(.prose > ul > li > p:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.editor-contents :where(.prose > ol > li > p:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.editor-contents :where(.prose > ol > li > p:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.editor-contents :where(hr + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .editor-contents :where(h2 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .editor-contents :where(h3 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .editor-contents :where(h4 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .editor-contents :where(.prose > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

.editor-contents :where(.prose > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 0;
}

.editor-contents :where(ol li):not(:where([class~="not-prose"], [class~="not-prose"] *))::marker {
  color: var(--tw-prose-counters);
  font-weight: 400;
}

.editor-contents :where(ul li):not(:where([class~="not-prose"], [class~="not-prose"] *))::marker {
  color: var(--tw-prose-bullets);
}

.editor-contents :where(.prose > ul li p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.editor-contents :where(.prose > ul li > p:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.editor-contents :where(.prose > ul li > p:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.editor-contents :where(.prose > ol li > p:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.editor-contents :where(.prose > ol li > p:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.editor-contents {
  font-size: .875rem;
  line-height: calc(var(--line-height-multiplier) * 1.71429);
}

.editor-contents :where(p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.14286em;
  margin-bottom: 1.14286em;
}

.editor-contents :where([class~="lead"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .888889em;
  margin-bottom: .888889em;
  font-size: 1.28571em;
  line-height: 1.55556;
}

.editor-contents :where(blockquote):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.33333em;
  margin-bottom: 1.33333em;
  padding-inline-start: 1.11111em;
}

.editor-contents :where(h1):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: .8em;
  font-size: 2.14286em;
  line-height: 1.2;
}

.editor-contents :where(h2):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.6em;
  margin-bottom: .8em;
  font-size: 1.42857em;
  line-height: 1.4;
}

.editor-contents :where(h3):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.55556em;
  margin-bottom: .444444em;
  font-size: 1.28571em;
  line-height: 1.55556;
}

.editor-contents :where(h4):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.42857em;
  margin-bottom: .571429em;
  line-height: 1.42857;
}

.editor-contents :where(img):not(:where([class~="not-prose"], [class~="not-prose"] *)), .editor-contents :where(picture):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
}

.editor-contents :where(picture > img):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.editor-contents :where(video):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
}

.editor-contents :where(kbd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-top: .142857em;
  padding-inline-end: .357143em;
  padding-bottom: .142857em;
  border-radius: .3125rem;
  padding-inline-start: .357143em;
  font-size: .857143em;
}

.editor-contents :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-size: .857143em;
}

.editor-contents :where(h2 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-size: .9em;
}

.editor-contents :where(h3 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-size: .888889em;
}

.editor-contents :where(pre):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-top: .666667em;
  padding-inline-end: 1em;
  padding-bottom: .666667em;
  border-radius: .25rem;
  margin-top: 1.66667em;
  margin-bottom: 1.66667em;
  padding-inline-start: 1em;
  font-size: .857143em;
  line-height: 1.66667;
}

.editor-contents :where(ol):not(:where([class~="not-prose"], [class~="not-prose"] *)), .editor-contents :where(ul):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.14286em;
  margin-bottom: 1.14286em;
  padding-inline-start: 1.57143em;
}

.editor-contents :where(li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .285714em;
  margin-bottom: .285714em;
}

.editor-contents :where(ol > li):not(:where([class~="not-prose"], [class~="not-prose"] *)), .editor-contents :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-inline-start: .428571em;
}

.editor-contents :where(.prose-sm > ul > li p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .571429em;
  margin-bottom: .571429em;
}

.editor-contents :where(.prose-sm > ul > li > p:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.14286em;
}

.editor-contents :where(.prose-sm > ul > li > p:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.14286em;
}

.editor-contents :where(.prose-sm > ol > li > p:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.14286em;
}

.editor-contents :where(.prose-sm > ol > li > p:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.14286em;
}

.editor-contents :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .571429em;
  margin-bottom: .571429em;
}

.editor-contents :where(dl):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.14286em;
  margin-bottom: 1.14286em;
}

.editor-contents :where(dt):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.14286em;
}

.editor-contents :where(dd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .285714em;
  padding-inline-start: 1.57143em;
}

.editor-contents :where(hr):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2.85714em;
  margin-bottom: 2.85714em;
}

.editor-contents :where(hr + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .editor-contents :where(h2 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .editor-contents :where(h3 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .editor-contents :where(h4 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

.editor-contents :where(table):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-size: .857143em;
  line-height: 1.5;
}

.editor-contents :where(thead th):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-inline-end: 1em;
  padding-bottom: .666667em;
  padding-inline-start: 1em;
}

.editor-contents :where(thead th:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-inline-start: 0;
}

.editor-contents :where(thead th:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-inline-end: 0;
}

.editor-contents :where(tbody td, tfoot td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-top: .666667em;
  padding-inline-end: 1em;
  padding-bottom: .666667em;
  padding-inline-start: 1em;
}

.editor-contents :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-inline-start: 0;
}

.editor-contents :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-inline-end: 0;
}

.editor-contents :where(figure):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
}

.editor-contents :where(figure > *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.editor-contents :where(figcaption):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .666667em;
  font-size: .857143em;
  line-height: 1.33333;
}

.editor-contents :where(.prose-sm > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

.editor-contents :where(.prose-sm > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 0;
}

.editor-contents :where(ol li):not(:where([class~="not-prose"], [class~="not-prose"] *)), .editor-contents :where(ul li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-inline-start: .428571em;
}

.editor-contents :where(.prose-sm > ul li p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .571429em;
  margin-bottom: .571429em;
}

.editor-contents :where(.prose-sm > ul li > p:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.14286em;
}

.editor-contents :where(.prose-sm > ul li > p:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.14286em;
}

.editor-contents :where(.prose-sm > ol li > p:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.14286em;
}

.editor-contents :where(.prose-sm > ol li > p:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.14286em;
}

@supports not (color: oklch(0% 0 0)) {
  .editor-contents :where(code):not(:where([class~="not-prose"] *, pre *)) {
    background-color: var(--fallback-b3, oklch(var(--b3) / 1));
  }
}

:root .editor-contents {
  --tw-prose-body: var(--fallback-bc, oklch(var(--bc) / .8));
  --tw-prose-headings: var(--fallback-bc, oklch(var(--bc) / 1));
  --tw-prose-lead: var(--fallback-bc, oklch(var(--bc) / 1));
  --tw-prose-links: var(--fallback-bc, oklch(var(--bc) / 1));
  --tw-prose-bold: var(--fallback-bc, oklch(var(--bc) / 1));
  --tw-prose-counters: var(--fallback-bc, oklch(var(--bc) / 1));
  --tw-prose-bullets: var(--fallback-bc, oklch(var(--bc) / .5));
  --tw-prose-hr: var(--fallback-bc, oklch(var(--bc) / .2));
  --tw-prose-quotes: var(--fallback-bc, oklch(var(--bc) / 1));
  --tw-prose-quote-borders: var(--fallback-bc, oklch(var(--bc) / .2));
  --tw-prose-captions: var(--fallback-bc, oklch(var(--bc) / .5));
  --tw-prose-code: var(--fallback-bc, oklch(var(--bc) / 1));
  --tw-prose-pre-code: var(--fallback-nc, oklch(var(--nc) / 1));
  --tw-prose-pre-bg: var(--fallback-n, oklch(var(--n) / 1));
  --tw-prose-th-borders: var(--fallback-bc, oklch(var(--bc) / .5));
  --tw-prose-td-borders: var(--fallback-bc, oklch(var(--bc) / .2));
}

.editor-contents :where(code):not(:where([class~="not-prose"] *, pre *)) {
  border-radius: var(--rounded-badge);
  font-weight: initial;
  background-color: var(--fallback-bc, oklch(var(--bc) / .1));
  padding: 1px 8px;
}

.editor-contents :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)):before, .editor-contents :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)):after {
  display: none;
}

.editor-contents pre code {
  border-radius: 0;
  padding: 0;
}

.editor-contents :where(tbody tr, thead):not(:where([class~="not-prose"] *)) {
  border-bottom-color: var(--fallback-bc, oklch(var(--bc) / .2));
}

.editor-contents {
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 640px) {
  .editor-contents {
    color: var(--tw-prose-body);
    max-width: 65ch;
  }

  .editor-contents :where(p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.25em;
    margin-bottom: 1.25em;
  }

  .editor-contents :where([class~="lead"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    color: var(--tw-prose-lead);
    margin-top: 1.2em;
    margin-bottom: 1.2em;
    font-size: 1.25em;
    line-height: 1.6;
  }

  .editor-contents :where(a):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    color: var(--tw-prose-links);
    font-weight: 500;
    text-decoration: underline;
  }

  .editor-contents :where(strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    color: var(--tw-prose-bold);
    font-weight: 600;
  }

  .editor-contents :where(a strong):not(:where([class~="not-prose"], [class~="not-prose"] *)), .editor-contents :where(blockquote strong):not(:where([class~="not-prose"], [class~="not-prose"] *)), .editor-contents :where(thead th strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    color: inherit;
  }

  .editor-contents :where(ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.25em;
    margin-bottom: 1.25em;
    padding-inline-start: 1.625em;
    list-style-type: decimal;
  }

  .editor-contents :where(ol[type="A"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    list-style-type: upper-alpha;
  }

  .editor-contents :where(ol[type="a"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    list-style-type: lower-alpha;
  }

  .editor-contents :where(ol[type="A" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    list-style-type: upper-alpha;
  }

  .editor-contents :where(ol[type="a" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    list-style-type: lower-alpha;
  }

  .editor-contents :where(ol[type="I"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    list-style-type: upper-roman;
  }

  .editor-contents :where(ol[type="i"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    list-style-type: lower-roman;
  }

  .editor-contents :where(ol[type="I" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    list-style-type: upper-roman;
  }

  .editor-contents :where(ol[type="i" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    list-style-type: lower-roman;
  }

  .editor-contents :where(ol[type="1"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    list-style-type: decimal;
  }

  .editor-contents :where(ul):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.25em;
    margin-bottom: 1.25em;
    padding-inline-start: 1.625em;
    list-style-type: disc;
  }

  .editor-contents :where(ol > li):not(:where([class~="not-prose"], [class~="not-prose"] *))::marker {
    color: var(--tw-prose-counters);
    font-weight: 400;
  }

  .editor-contents :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *))::marker {
    color: var(--tw-prose-bullets);
  }

  .editor-contents :where(dt):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    color: var(--tw-prose-headings);
    margin-top: 1.25em;
    font-weight: 600;
  }

  .editor-contents :where(hr):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    border-color: var(--tw-prose-hr);
    border-top-width: 1px;
    margin-top: 3em;
    margin-bottom: 3em;
  }

  .editor-contents :where(blockquote):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    color: var(--tw-prose-quotes);
    border-inline-start-width: .25rem;
    border-inline-start-color: var(--tw-prose-quote-borders);
    quotes: "“""”""‘""’";
    margin-top: 1.6em;
    margin-bottom: 1.6em;
    padding-inline-start: 1em;
    font-style: normal;
    font-weight: 500;
  }

  .editor-contents :where(blockquote p:first-of-type):not(:where([class~="not-prose"], [class~="not-prose"] *)):before, .editor-contents :where(blockquote p:last-of-type):not(:where([class~="not-prose"], [class~="not-prose"] *)):after {
    content: ;
  }

  .editor-contents :where(h1):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    color: var(--tw-prose-headings);
    margin-top: 0;
    margin-bottom: .888889em;
    font-size: 2.25em;
    font-weight: 800;
    line-height: 1.11111;
  }

  .editor-contents :where(h1 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    color: inherit;
    font-weight: 900;
  }

  .editor-contents :where(h2):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    color: var(--tw-prose-headings);
    margin-top: 2em;
    margin-bottom: 1em;
    font-size: 1.5em;
    font-weight: 700;
    line-height: 1.33333;
  }

  .editor-contents :where(h2 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    color: inherit;
    font-weight: 800;
  }

  .editor-contents :where(h3):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    color: var(--tw-prose-headings);
    margin-top: 1.6em;
    margin-bottom: .6em;
    font-size: 1.25em;
    font-weight: 600;
    line-height: 1.6;
  }

  .editor-contents :where(h3 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    color: inherit;
    font-weight: 700;
  }

  .editor-contents :where(h4):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    color: var(--tw-prose-headings);
    margin-top: 1.5em;
    margin-bottom: .5em;
    font-weight: 600;
    line-height: 1.5;
  }

  .editor-contents :where(h4 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    color: inherit;
    font-weight: 700;
  }

  .editor-contents :where(img):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .editor-contents :where(picture):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 2em;
    margin-bottom: 2em;
    display: block;
  }

  .editor-contents :where(video):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .editor-contents :where(kbd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    color: var(--tw-prose-kbd);
    box-shadow: 0 0 0 1px rgb(var(--tw-prose-kbd-shadows) / 10%), 0 3px 0 rgb(var(--tw-prose-kbd-shadows) / 10%);
    padding-top: .1875em;
    padding-inline-end: .375em;
    padding-bottom: .1875em;
    border-radius: .3125rem;
    padding-inline-start: .375em;
    font-family: inherit;
    font-size: .875em;
    font-weight: 500;
  }

  .editor-contents :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    color: var(--tw-prose-code);
    font-size: .875em;
    font-weight: 600;
  }

  .editor-contents :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)):before, .editor-contents :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)):after {
    content: "`";
  }

  .editor-contents :where(a code):not(:where([class~="not-prose"], [class~="not-prose"] *)), .editor-contents :where(h1 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    color: inherit;
  }

  .editor-contents :where(h2 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    color: inherit;
    font-size: .875em;
  }

  .editor-contents :where(h3 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    color: inherit;
    font-size: .9em;
  }

  .editor-contents :where(h4 code):not(:where([class~="not-prose"], [class~="not-prose"] *)), .editor-contents :where(blockquote code):not(:where([class~="not-prose"], [class~="not-prose"] *)), .editor-contents :where(thead th code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    color: inherit;
  }

  .editor-contents :where(pre):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    color: var(--tw-prose-pre-code);
    background-color: var(--tw-prose-pre-bg);
    padding-top: .857143em;
    padding-inline-end: 1.14286em;
    padding-bottom: .857143em;
    border-radius: .375rem;
    margin-top: 1.71429em;
    margin-bottom: 1.71429em;
    padding-inline-start: 1.14286em;
    font-size: .875em;
    font-weight: 400;
    line-height: 1.71429;
    overflow-x: auto;
  }

  .editor-contents :where(pre code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-weight: inherit;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
    background-color: #0000;
    border-width: 0;
    border-radius: 0;
    padding: 0;
  }

  .editor-contents :where(pre code):not(:where([class~="not-prose"], [class~="not-prose"] *)):before, .editor-contents :where(pre code):not(:where([class~="not-prose"], [class~="not-prose"] *)):after {
    content: none;
  }

  .editor-contents :where(table):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    table-layout: auto;
    width: 100%;
    margin-top: 2em;
    margin-bottom: 2em;
    font-size: .875em;
    line-height: 1.71429;
  }

  .editor-contents :where(thead):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    border-bottom-width: 1px;
    border-bottom-color: var(--tw-prose-th-borders);
  }

  .editor-contents :where(thead th):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    color: var(--tw-prose-headings);
    vertical-align: bottom;
    padding-inline-end: .571429em;
    padding-bottom: .571429em;
    padding-inline-start: .571429em;
    font-weight: 600;
  }

  .editor-contents :where(tbody tr):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    border-bottom-width: 1px;
    border-bottom-color: var(--tw-prose-td-borders);
  }

  .editor-contents :where(tbody tr:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    border-bottom-width: 0;
  }

  .editor-contents :where(tbody td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    vertical-align: baseline;
  }

  .editor-contents :where(tfoot):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    border-top-width: 1px;
    border-top-color: var(--tw-prose-th-borders);
  }

  .editor-contents :where(tfoot td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    vertical-align: top;
  }

  .editor-contents :where(th, td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    text-align: start;
  }

  .editor-contents :where(figure > *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
    margin-bottom: 0;
  }

  .editor-contents :where(figcaption):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    color: inherit;
    margin-top: .857143em;
    font-size: .875em;
    line-height: 1.42857;
  }

  .editor-contents {
    --tw-prose-body: #374151;
    --tw-prose-headings: #111827;
    --tw-prose-lead: #4b5563;
    --tw-prose-links: #111827;
    --tw-prose-bold: #111827;
    --tw-prose-counters: #6b7280;
    --tw-prose-bullets: #d1d5db;
    --tw-prose-hr: #e5e7eb;
    --tw-prose-quotes: #111827;
    --tw-prose-quote-borders: #e5e7eb;
    --tw-prose-captions: #6b7280;
    --tw-prose-kbd: #111827;
    --tw-prose-kbd-shadows: 17 24 39;
    --tw-prose-code: #111827;
    --tw-prose-pre-code: #e5e7eb;
    --tw-prose-pre-bg: #1f2937;
    --tw-prose-th-borders: #d1d5db;
    --tw-prose-td-borders: #e5e7eb;
    --tw-prose-invert-body: #d1d5db;
    --tw-prose-invert-headings: #fff;
    --tw-prose-invert-lead: #9ca3af;
    --tw-prose-invert-links: #fff;
    --tw-prose-invert-bold: #fff;
    --tw-prose-invert-counters: #9ca3af;
    --tw-prose-invert-bullets: #4b5563;
    --tw-prose-invert-hr: #374151;
    --tw-prose-invert-quotes: #f3f4f6;
    --tw-prose-invert-quote-borders: #374151;
    --tw-prose-invert-captions: #9ca3af;
    --tw-prose-invert-kbd: #fff;
    --tw-prose-invert-kbd-shadows: 255 255 255;
    --tw-prose-invert-code: #fff;
    --tw-prose-invert-pre-code: #d1d5db;
    --tw-prose-invert-pre-bg: #00000080;
    --tw-prose-invert-th-borders: #4b5563;
    --tw-prose-invert-td-borders: #374151;
    font-size: 1rem;
    line-height: calc(var(--line-height-multiplier) * 1.75);
  }

  .editor-contents :where(picture > img):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
    margin-bottom: 0;
  }

  .editor-contents :where(li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: .5em;
    margin-bottom: .5em;
  }

  .editor-contents :where(ol > li):not(:where([class~="not-prose"], [class~="not-prose"] *)), .editor-contents :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-inline-start: .375em;
  }

  .editor-contents :where(.sm\:prose > ul > li p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: .75em;
    margin-bottom: .75em;
  }

  .editor-contents :where(.sm\:prose > ul > li > p:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.25em;
  }

  .editor-contents :where(.sm\:prose > ul > li > p:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-bottom: 1.25em;
  }

  .editor-contents :where(.sm\:prose > ol > li > p:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.25em;
  }

  .editor-contents :where(.sm\:prose > ol > li > p:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-bottom: 1.25em;
  }

  .editor-contents :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: .75em;
    margin-bottom: .75em;
  }

  .editor-contents :where(dl):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.25em;
    margin-bottom: 1.25em;
  }

  .editor-contents :where(dd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: .5em;
    padding-inline-start: 1.625em;
  }

  .editor-contents :where(hr + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .editor-contents :where(h2 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .editor-contents :where(h3 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .editor-contents :where(h4 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
  }

  .editor-contents :where(thead th:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-inline-start: 0;
  }

  .editor-contents :where(thead th:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-inline-end: 0;
  }

  .editor-contents :where(tbody td, tfoot td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-top: .571429em;
    padding-inline-end: .571429em;
    padding-bottom: .571429em;
    padding-inline-start: .571429em;
  }

  .editor-contents :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-inline-start: 0;
  }

  .editor-contents :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-inline-end: 0;
  }

  .editor-contents :where(figure):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .editor-contents :where(.sm\:prose > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
  }

  .editor-contents :where(.sm\:prose > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-bottom: 0;
  }

  .editor-contents :where(ol li):not(:where([class~="not-prose"], [class~="not-prose"] *))::marker {
    color: var(--tw-prose-counters);
    font-weight: 400;
  }

  .editor-contents :where(ul li):not(:where([class~="not-prose"], [class~="not-prose"] *))::marker {
    color: var(--tw-prose-bullets);
  }

  .editor-contents :where(ol li):not(:where([class~="not-prose"], [class~="not-prose"] *)), .editor-contents :where(ul li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-inline-start: .375em;
  }

  .editor-contents :where(.sm\:prose > ul li p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: .75em;
    margin-bottom: .75em;
  }

  .editor-contents :where(.sm\:prose > ul li > p:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.25em;
  }

  .editor-contents :where(.sm\:prose > ul li > p:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-bottom: 1.25em;
  }

  .editor-contents :where(.sm\:prose > ol li > p:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.25em;
  }

  .editor-contents :where(.sm\:prose > ol li > p:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-bottom: 1.25em;
  }

  @supports not (color: oklch(0% 0 0)) {
    .editor-contents :where(code):not(:where([class~="not-prose"] *, pre *)) {
      background-color: var(--fallback-b3, oklch(var(--b3) / 1));
    }
  }

  :root .editor-contents {
    --tw-prose-body: var(--fallback-bc, oklch(var(--bc) / .8));
    --tw-prose-headings: var(--fallback-bc, oklch(var(--bc) / 1));
    --tw-prose-lead: var(--fallback-bc, oklch(var(--bc) / 1));
    --tw-prose-links: var(--fallback-bc, oklch(var(--bc) / 1));
    --tw-prose-bold: var(--fallback-bc, oklch(var(--bc) / 1));
    --tw-prose-counters: var(--fallback-bc, oklch(var(--bc) / 1));
    --tw-prose-bullets: var(--fallback-bc, oklch(var(--bc) / .5));
    --tw-prose-hr: var(--fallback-bc, oklch(var(--bc) / .2));
    --tw-prose-quotes: var(--fallback-bc, oklch(var(--bc) / 1));
    --tw-prose-quote-borders: var(--fallback-bc, oklch(var(--bc) / .2));
    --tw-prose-captions: var(--fallback-bc, oklch(var(--bc) / .5));
    --tw-prose-code: var(--fallback-bc, oklch(var(--bc) / 1));
    --tw-prose-pre-code: var(--fallback-nc, oklch(var(--nc) / 1));
    --tw-prose-pre-bg: var(--fallback-n, oklch(var(--n) / 1));
    --tw-prose-th-borders: var(--fallback-bc, oklch(var(--bc) / .5));
    --tw-prose-td-borders: var(--fallback-bc, oklch(var(--bc) / .2));
  }

  .editor-contents :where(code):not(:where([class~="not-prose"] *, pre *)) {
    border-radius: var(--rounded-badge);
    font-weight: initial;
    background-color: var(--fallback-bc, oklch(var(--bc) / .1));
    padding: 1px 8px;
  }

  .editor-contents :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)):before, .editor-contents :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)):after {
    display: none;
  }

  .editor-contents pre code {
    border-radius: 0;
    padding: 0;
  }

  .editor-contents :where(tbody tr, thead):not(:where([class~="not-prose"] *)) {
    border-bottom-color: var(--fallback-bc, oklch(var(--bc) / .2));
  }
}

@media (width >= 1024px) {
  .editor-contents {
    font-size: 1.125rem;
    line-height: calc(var(--line-height-multiplier) * 1.77778);
  }

  .editor-contents :where(p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.33333em;
    margin-bottom: 1.33333em;
  }

  .editor-contents :where([class~="lead"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.09091em;
    margin-bottom: 1.09091em;
    font-size: 1.22222em;
    line-height: 1.45455;
  }

  .editor-contents :where(blockquote):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.66667em;
    margin-bottom: 1.66667em;
    padding-inline-start: 1em;
  }

  .editor-contents :where(h1):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
    margin-bottom: .833333em;
    font-size: 2.66667em;
    line-height: 1;
  }

  .editor-contents :where(h2):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.86667em;
    margin-bottom: 1.06667em;
    font-size: 1.66667em;
    line-height: 1.33333;
  }

  .editor-contents :where(h3):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.66667em;
    margin-bottom: .666667em;
    font-size: 1.33333em;
    line-height: 1.5;
  }

  .editor-contents :where(h4):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.77778em;
    margin-bottom: .444444em;
    line-height: 1.55556;
  }

  .editor-contents :where(img):not(:where([class~="not-prose"], [class~="not-prose"] *)), .editor-contents :where(picture):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.77778em;
    margin-bottom: 1.77778em;
  }

  .editor-contents :where(picture > img):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
    margin-bottom: 0;
  }

  .editor-contents :where(video):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.77778em;
    margin-bottom: 1.77778em;
  }

  .editor-contents :where(kbd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-top: .222222em;
    padding-inline-end: .444444em;
    padding-bottom: .222222em;
    border-radius: .3125rem;
    padding-inline-start: .444444em;
    font-size: .888889em;
  }

  .editor-contents :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: .888889em;
  }

  .editor-contents :where(h2 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: .866667em;
  }

  .editor-contents :where(h3 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: .875em;
  }

  .editor-contents :where(pre):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-top: 1em;
    padding-inline-end: 1.5em;
    padding-bottom: 1em;
    border-radius: .375rem;
    margin-top: 2em;
    margin-bottom: 2em;
    padding-inline-start: 1.5em;
    font-size: .888889em;
    line-height: 1.75;
  }

  .editor-contents :where(ol):not(:where([class~="not-prose"], [class~="not-prose"] *)), .editor-contents :where(ul):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.33333em;
    margin-bottom: 1.33333em;
    padding-inline-start: 1.55556em;
  }

  .editor-contents :where(li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: .666667em;
    margin-bottom: .666667em;
  }

  .editor-contents :where(ol > li):not(:where([class~="not-prose"], [class~="not-prose"] *)), .editor-contents :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-inline-start: .444444em;
  }

  .editor-contents :where(.lg\:prose-lg > ul > li p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: .888889em;
    margin-bottom: .888889em;
  }

  .editor-contents :where(.lg\:prose-lg > ul > li > p:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.33333em;
  }

  .editor-contents :where(.lg\:prose-lg > ul > li > p:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-bottom: 1.33333em;
  }

  .editor-contents :where(.lg\:prose-lg > ol > li > p:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.33333em;
  }

  .editor-contents :where(.lg\:prose-lg > ol > li > p:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-bottom: 1.33333em;
  }

  .editor-contents :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: .888889em;
    margin-bottom: .888889em;
  }

  .editor-contents :where(dl):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.33333em;
    margin-bottom: 1.33333em;
  }

  .editor-contents :where(dt):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.33333em;
  }

  .editor-contents :where(dd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: .666667em;
    padding-inline-start: 1.55556em;
  }

  .editor-contents :where(hr):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 3.11111em;
    margin-bottom: 3.11111em;
  }

  .editor-contents :where(hr + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .editor-contents :where(h2 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .editor-contents :where(h3 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .editor-contents :where(h4 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
  }

  .editor-contents :where(table):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: .888889em;
    line-height: 1.5;
  }

  .editor-contents :where(thead th):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-inline-end: .75em;
    padding-bottom: .75em;
    padding-inline-start: .75em;
  }

  .editor-contents :where(thead th:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-inline-start: 0;
  }

  .editor-contents :where(thead th:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-inline-end: 0;
  }

  .editor-contents :where(tbody td, tfoot td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-top: .75em;
    padding-inline-end: .75em;
    padding-bottom: .75em;
    padding-inline-start: .75em;
  }

  .editor-contents :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-inline-start: 0;
  }

  .editor-contents :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-inline-end: 0;
  }

  .editor-contents :where(figure):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.77778em;
    margin-bottom: 1.77778em;
  }

  .editor-contents :where(figure > *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
    margin-bottom: 0;
  }

  .editor-contents :where(figcaption):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1em;
    font-size: .888889em;
    line-height: 1.5;
  }

  .editor-contents :where(.lg\:prose-lg > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
  }

  .editor-contents :where(.lg\:prose-lg > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-bottom: 0;
  }

  .editor-contents :where(ol li):not(:where([class~="not-prose"], [class~="not-prose"] *)), .editor-contents :where(ul li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-inline-start: .444444em;
  }

  .editor-contents :where(.lg\:prose-lg > ul li p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: .888889em;
    margin-bottom: .888889em;
  }

  .editor-contents :where(.lg\:prose-lg > ul li > p:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.33333em;
  }

  .editor-contents :where(.lg\:prose-lg > ul li > p:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-bottom: 1.33333em;
  }

  .editor-contents :where(.lg\:prose-lg > ol li > p:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.33333em;
  }

  .editor-contents :where(.lg\:prose-lg > ol li > p:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-bottom: 1.33333em;
  }
}

@media (width >= 1280px) {
  .editor-contents {
    font-size: 1.25rem;
    line-height: calc(var(--line-height-multiplier) * 1.8);
  }

  .editor-contents :where(p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.2em;
    margin-bottom: 1.2em;
  }

  .editor-contents :where([class~="lead"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1em;
    margin-bottom: 1em;
    font-size: 1.2em;
    line-height: 1.5;
  }

  .editor-contents :where(blockquote):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.6em;
    margin-bottom: 1.6em;
    padding-inline-start: 1.06667em;
  }

  .editor-contents :where(h1):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
    margin-bottom: .857143em;
    font-size: 2.8em;
    line-height: 1;
  }

  .editor-contents :where(h2):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.55556em;
    margin-bottom: .888889em;
    font-size: 1.8em;
    line-height: 1.11111;
  }

  .editor-contents :where(h3):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.6em;
    margin-bottom: .666667em;
    font-size: 1.5em;
    line-height: 1.33333;
  }

  .editor-contents :where(h4):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.8em;
    margin-bottom: .6em;
    line-height: 1.6;
  }

  .editor-contents :where(img):not(:where([class~="not-prose"], [class~="not-prose"] *)), .editor-contents :where(picture):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .editor-contents :where(picture > img):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
    margin-bottom: 0;
  }

  .editor-contents :where(video):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .editor-contents :where(kbd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-top: .25em;
    padding-inline-end: .4em;
    padding-bottom: .25em;
    border-radius: .3125rem;
    padding-inline-start: .4em;
    font-size: .9em;
  }

  .editor-contents :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: .9em;
  }

  .editor-contents :where(h2 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: .861111em;
  }

  .editor-contents :where(h3 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: .9em;
  }

  .editor-contents :where(pre):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-top: 1.11111em;
    padding-inline-end: 1.33333em;
    padding-bottom: 1.11111em;
    border-radius: .5rem;
    margin-top: 2em;
    margin-bottom: 2em;
    padding-inline-start: 1.33333em;
    font-size: .9em;
    line-height: 1.77778;
  }

  .editor-contents :where(ol):not(:where([class~="not-prose"], [class~="not-prose"] *)), .editor-contents :where(ul):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.2em;
    margin-bottom: 1.2em;
    padding-inline-start: 1.6em;
  }

  .editor-contents :where(li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: .6em;
    margin-bottom: .6em;
  }

  .editor-contents :where(ol > li):not(:where([class~="not-prose"], [class~="not-prose"] *)), .editor-contents :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-inline-start: .4em;
  }

  .editor-contents :where(.xl\:prose-xl > ul > li p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: .8em;
    margin-bottom: .8em;
  }

  .editor-contents :where(.xl\:prose-xl > ul > li > p:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.2em;
  }

  .editor-contents :where(.xl\:prose-xl > ul > li > p:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-bottom: 1.2em;
  }

  .editor-contents :where(.xl\:prose-xl > ol > li > p:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.2em;
  }

  .editor-contents :where(.xl\:prose-xl > ol > li > p:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-bottom: 1.2em;
  }

  .editor-contents :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: .8em;
    margin-bottom: .8em;
  }

  .editor-contents :where(dl):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.2em;
    margin-bottom: 1.2em;
  }

  .editor-contents :where(dt):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.2em;
  }

  .editor-contents :where(dd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: .6em;
    padding-inline-start: 1.6em;
  }

  .editor-contents :where(hr):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 2.8em;
    margin-bottom: 2.8em;
  }

  .editor-contents :where(hr + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .editor-contents :where(h2 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .editor-contents :where(h3 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .editor-contents :where(h4 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
  }

  .editor-contents :where(table):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    font-size: .9em;
    line-height: 1.55556;
  }

  .editor-contents :where(thead th):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-inline-end: .666667em;
    padding-bottom: .888889em;
    padding-inline-start: .666667em;
  }

  .editor-contents :where(thead th:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-inline-start: 0;
  }

  .editor-contents :where(thead th:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-inline-end: 0;
  }

  .editor-contents :where(tbody td, tfoot td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-top: .888889em;
    padding-inline-end: .666667em;
    padding-bottom: .888889em;
    padding-inline-start: .666667em;
  }

  .editor-contents :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-inline-start: 0;
  }

  .editor-contents :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-inline-end: 0;
  }

  .editor-contents :where(figure):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .editor-contents :where(figure > *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
    margin-bottom: 0;
  }

  .editor-contents :where(figcaption):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1em;
    font-size: .9em;
    line-height: 1.55556;
  }

  .editor-contents :where(.xl\:prose-xl > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 0;
  }

  .editor-contents :where(.xl\:prose-xl > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-bottom: 0;
  }

  .editor-contents :where(ol li):not(:where([class~="not-prose"], [class~="not-prose"] *)), .editor-contents :where(ul li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    padding-inline-start: .4em;
  }

  .editor-contents :where(.xl\:prose-xl > ul li p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: .8em;
    margin-bottom: .8em;
  }

  .editor-contents :where(.xl\:prose-xl > ul li > p:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.2em;
  }

  .editor-contents :where(.xl\:prose-xl > ul li > p:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-bottom: 1.2em;
  }

  .editor-contents :where(.xl\:prose-xl > ol li > p:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-top: 1.2em;
  }

  .editor-contents :where(.xl\:prose-xl > ol li > p:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
    margin-bottom: 1.2em;
  }
}

.sidebar__content {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-bg-opacity, 1)));
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  width: 24rem;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  z-index: 10;
  height: calc(100dvh - var(--total-header));
  width: min(24rem, 100dvw);
  padding: .5rem;
  position: fixed;
  bottom: 0;
  overflow-y: scroll;
}

[data-no-sticky] .sidebar__content {
  height: fit-content;
  bottom: unset;
  position: absolute;
}

.sidebar__header {
  align-items: center;
  display: flex;
}

.sidebar__header svg {
  transition: transform .1s ease-in;
}

.sidebar__header[data-state="open"] .sidebar__chevron svg {
  transform: rotate(90deg);
}

.sidebar__link {
  width: 100%;
  display: inline-block;
}

.sidebar__link:hover {
  --tw-text-opacity: 1;
  color: var(--fallback-p, oklch(var(--p) / var(--tw-text-opacity, 1)));
}

.sidebar__link {
  outline-offset: 2px;
  outline-width: 2px;
  outline-color: var(--fallback-bc, oklch(var(--bc) / 1));
}

.sidebar__link:focus-visible {
  outline-style: solid;
}

.sidebar__link--active {
  --tw-text-opacity: 1;
  color: var(--fallback-p, oklch(var(--p) / var(--tw-text-opacity, 1)));
}

.sidebar-row {
  flex: 1;
  display: flex;
}

.sidebar-row__title {
  flex: 1;
}

.sidebar-row__actions {
  display: flex;
}

.sidebar-modal {
  --tw-translate-x: 50%;
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity, 1)));
  border-radius: .25rem;
  padding: 2rem;
  position: fixed;
  top: 50%;
  right: 50%;
}

.sidebar-modal__actions {
  justify-content: center;
  margin-top: 1.5rem;
  display: flex;
}

.sidebar-modal__actions > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.modifiers {
  min-width: 120px;
  padding: .25rem .5rem;
}

[data-font-family="serif"] {
  font-family: serif;
}

[data-font-family="sans"] {
  font-family: sans-serif;
}

[data-font-family="readable"] {
  font-family: Biancoenero, sans-serif;
}

[data-font-case="normal"] {
  text-transform: none;
}

[data-font-case="uppercase"] {
  text-transform: uppercase;
}

[data-font-size="0"] {
  font-size: 16px;
}

[data-font-size="1"] {
  font-size: 18px;
}

[data-font-size="2"] {
  font-size: 20px;
}

[data-font-space="normal"] {
  --line-height-multiplier: 1;
}

[data-font-space="large"] {
  --line-height-multiplier: 1.25;
}

[data-font-space="double"] {
  --line-height-multiplier: 1.5;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity .3s;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity .3s;
}

@keyframes slide-in {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slide-out {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

.sidebar__content[data-state="open"] {
  animation: .3s ease-out slide-in;
}

.sidebar__content[data-state="closed"] {
  animation: .3s ease-in slide-out;
}

.dropdown .popover__content {
  position: absolute;
}

.dropdown:not(details) .popover__content {
  visibility: hidden;
  opacity: 0;
  transform-origin: top;
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.dropdown-end .popover__content {
  inset-inline-end: 0;
}

.dropdown-left .popover__content {
  bottom: auto;
  transform-origin: 100%;
  inset-inline-end: 100%;
  top: 0;
}

.dropdown-right .popover__content {
  bottom: auto;
  transform-origin: 0;
  inset-inline-start: 100%;
  top: 0;
}

.dropdown-bottom .popover__content {
  transform-origin: top;
  top: 100%;
  bottom: auto;
}

.dropdown-top .popover__content {
  transform-origin: bottom;
  top: auto;
  bottom: 100%;
}

.dropdown-end.dropdown-right .popover__content, .dropdown-end.dropdown-left .popover__content {
  top: auto;
  bottom: 0;
}

.dropdown.dropdown-open .popover__content, .dropdown:not(.dropdown-hover):focus .popover__content, .dropdown:focus-within .popover__content {
  visibility: visible;
  opacity: 1;
}

@media (hover: hover) {
  .dropdown.dropdown-hover:hover .popover__content {
    visibility: visible;
    opacity: 1;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}

.dropdown.dropdown-open .popover__content, .dropdown:focus .popover__content, .dropdown:focus-within .popover__content {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.dropdown .popover__content {
  z-index: 2000;
}

.popover__content {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity, 1)));
  --tw-text-opacity: 1;
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity, 1)));
  z-index: 10;
  border-radius: var(--rounded-box, 1rem);
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-border-opacity, 1)));
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  max-height: calc(100dvh - var(--total-header));
  padding: 1rem;
  overflow: auto;
}

[data-no-sticky] .popover__content {
  max-height: unset;
}

.tooltip__content {
  border-radius: var(--rounded-box, 1rem);
  --tw-bg-opacity: 1;
  background-color: var(--fallback-p, oklch(var(--p) / var(--tw-bg-opacity, 1)));
  --tw-text-opacity: 1;
  color: var(--fallback-pc, oklch(var(--pc) / var(--tw-text-opacity, 1)));
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  padding: .5rem 1rem;
}

.tooltip__arrow {
  fill: var(--fallback-p, oklch(var(--p) / 1));
}

@media (hover: hover) {
  @supports (color: color-mix(in oklab, black, black)) {
    .btn-outline.modifier-btn:disabled:hover {
      background-color: color-mix(in oklab, var(--fallback-p, oklch(var(--p) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-p, oklch(var(--p) / 1)) 90%, black);
    }
  }

  .btn-outline.modifier-btn:disabled:hover {
    --tw-text-opacity: 1;
    color: var(--fallback-pc, oklch(var(--pc) / var(--tw-text-opacity)));
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .btn-outline.modifier-btn:disabled:hover {
      background-color: color-mix(in oklab, var(--fallback-p, oklch(var(--p) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-p, oklch(var(--p) / 1)) 90%, black);
    }
  }
}

@supports not (color: oklch(0% 0 0)) {
  .modifier-btn:disabled {
    --btn-color: var(--fallback-p);
  }
}

@supports (color: color-mix(in oklab, black, black)) {
  .btn-outline.modifier-btn:disabled.btn-active {
    background-color: color-mix(in oklab, var(--fallback-p, oklch(var(--p) / 1)) 90%, black);
    border-color: color-mix(in oklab, var(--fallback-p, oklch(var(--p) / 1)) 90%, black);
  }
}

.modifier-btn:disabled {
  --tw-text-opacity: 1;
  color: var(--fallback-pc, oklch(var(--pc) / var(--tw-text-opacity)));
  outline-color: var(--fallback-p, oklch(var(--p) / 1));
}

@supports (color: oklch(0% 0 0)) {
  .modifier-btn:disabled {
    --btn-color: var(--p);
  }
}

.btn-outline.modifier-btn:disabled {
  --tw-text-opacity: 1;
  color: var(--fallback-p, oklch(var(--p) / var(--tw-text-opacity)));
}

.btn-outline.modifier-btn:disabled.btn-active {
  --tw-text-opacity: 1;
  color: var(--fallback-pc, oklch(var(--pc) / var(--tw-text-opacity)));
}

.cookie-banner {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b3, oklch(var(--b3) / var(--tw-bg-opacity, 1)));
  z-index: 40;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  max-width: 1024px;
  margin: auto;
  padding: 16px;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (width >= 768px) {
  .cookie-banner {
    flex-direction: row;
    align-items: center;
    padding: 16px 90px;
  }
}

.cookie-banner__text {
  margin-bottom: 1rem;
}

@media (width >= 768px) {
  .cookie-banner__text {
    margin-bottom: 0;
    padding-right: 8rem;
  }
}

.glossario__trigger:focus-within {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-a, oklch(var(--a) / var(--tw-bg-opacity, 1)));
  --tw-text-opacity: 1;
  color: var(--fallback-ac, oklch(var(--ac) / var(--tw-text-opacity, 1)));
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.glossario__trigger-content {
  --tw-border-opacity: 1;
  border-bottom-width: 2px;
  border-color: var(--fallback-p, oklch(var(--p) / var(--tw-border-opacity, 1)));
}

.glossario__trigger-content:hover {
  cursor: pointer;
}

.glossario__content {
  z-index: 101;
  --tw-bg-opacity: 1;
  background-color: var(--fallback-p, oklch(var(--p) / var(--tw-bg-opacity, 1)));
  --tw-text-opacity: 1;
  max-width: 250px;
  color: var(--fallback-pc, oklch(var(--pc) / var(--tw-text-opacity, 1)));
  border-radius: .25rem;
  padding: .5rem;
}

@media (width >= 640px) {
  .glossario__content {
    max-width: 32rem;
  }
}

.glossario__content.anchor-right {
  border-bottom-right-radius: 0;
}

.glossario__content.anchor-left {
  border-bottom-left-radius: 0;
}

.glossario__arrow {
  fill: var(--fallback-p, oklch(var(--p) / 1));
  background-color: #0000;
}

.btn--inverted {
  --tw-border-opacity: 1;
  border-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-border-opacity, 1)));
  --tw-bg-opacity: 1;
  background-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-bg-opacity, 1)));
  --tw-text-opacity: 1;
  color: var(--fallback-b2, oklch(var(--b2) / var(--tw-text-opacity, 1)));
  outline-color: var(--fallback-bc, oklch(var(--bc) / 1));
}

@media (hover: hover) {
  .btn--inverted:hover {
    --tw-border-opacity: 1;
    border-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-border-opacity, 1)));
    --tw-bg-opacity: 1;
    background-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-bg-opacity, 1)));
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .btn--inverted:hover {
      background-color: color-mix(in oklab, oklch(var(--btn-color, var(--bc)) / var(--tw-bg-opacity, 1)) 80%, oklch(var(--b2) / var(--tw-bg-opacity)));
      border-color: color-mix(in oklab, oklch(var(--btn-color, var(--bc)) / var(--tw-border-opacity, 1)) 80%, oklch(var(--b2) / var(--tw-bg-opacity)));
    }
  }

  @supports not (color: oklch(0% 0 0)) {
    .btn--inverted:hover {
      background-color: var(--btn-color, var(--fallback-b2));
      border-color: var(--btn-color, var(--fallback-b2));
    }
  }
}

.skipnavlinks {
  z-index: 40;
  justify-content: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.skipnavlinks > a {
  --tw-translate-y: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-border-opacity: 1;
  border-color: var(--fallback-a, oklch(var(--a) / var(--tw-border-opacity, 1)));
  --tw-bg-opacity: 1;
  background-color: var(--fallback-a, oklch(var(--a) / var(--tw-bg-opacity, 1)));
  --tw-text-opacity: 1;
  color: var(--fallback-ac, oklch(var(--ac) / var(--tw-text-opacity, 1)));
  outline-offset: 2px;
  outline-width: 2px;
  outline-color: var(--fallback-a, oklch(var(--a) / 1));
  --hover-w: 3rem;
  --hover-h: 1rem;
  --hover-left: calc(0rem - (var(--hover-w) / 2));
  --hover-bottom: calc(0rem - (var(--hover-h) / 2));
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem;
  padding: .5rem;
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: block;
  position: relative;
}

.skipnavlinks > a .highlight-box {
  margin-top: 0;
  margin-bottom: 0;
}

.example-block .skipnavlinks > a + .block p {
  padding-top: 1.25rem;
}

.skipnavlinks > a:focus {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.skipnavlinks > a:focus-visible {
  outline-style: solid;
}

@media (prefers-reduced-motion: reduce) {
  .skipnavlinks > a {
    transition-property: none;
  }
}

.anchor-center {
  --anchor-transform-pc: 50%;
}

.anchor-left {
  --anchor-transform-pc: 0%;
}

.anchor-right {
  --anchor-transform-pc: 100%;
}

.toggletip__content {
  z-index: 5;
  transform: translate(calc(var(--mt-content-x, var(--anchor-transform-pc, 50%))  - var(--anchor-transform-pc)), calc(var(--mt-content-y, 100%)  - var(--mt-arrow-h, 0)  - 100%));
  position: absolute;
  top: 0;
  left: 0;
}

.toggletip__content:empty {
  display: none;
}

.toggletip__content:has(.toggletip__arrow) {
  --mt-arrow-h: 5px;
}

.toggletip__arrow {
  --tw-translate-x: calc(-1 * var(--anchor-transform-pc));
  --tw-translate-y: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  bottom: 0;
  left: var(--anchor-transform-pc);
  display: block;
  position: absolute;
}

.ui-customizer__option {
  margin-bottom: 1rem;
}

.ui-customizer__option label:has(input:focus-visible) {
  outline-style: solid;
}

.editor-contents .heading {
  scroll-margin-top: var(--total-header);
}

.editor-contents .quote figcaption {
  text-align: right;
  max-width: 70%;
  margin-left: auto;
}

.block {
  --hover-w: 3rem;
  --hover-h: 1rem;
  --hover-left: calc(0rem - (var(--hover-w) / 2));
  --hover-bottom: calc(0rem - (var(--hover-h) / 2));
  position: relative;
}

.block .highlight-box {
  margin-top: 0;
  margin-bottom: 0;
}

.block-beginning {
  --hover-w: 0;
  --hover-h: 0;
  --hover-left: 0;
  --hover-bottom: 0;
}

.block__wrapper {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.block--highlighted:before, .block--empty:before {
  width: calc(100% + var(--hover-w));
  height: calc(100% + var(--hover-h));
  top: var(--hover-bottom);
  left: var(--hover-left);
  content: "";
  z-index: -1;
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-bg-opacity, 1)));
  border-radius: .125rem;
  position: absolute;
}

.block-beginning:hover, .block--highlighted {
  z-index: 1;
}

.block-beginning:hover > .block-tools, .block--highlighted > .block-tools {
  visibility: visible;
}

.block--parent {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.block-tools__trigger {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-p, oklch(var(--p) / var(--tw-bg-opacity, 1)));
  border-radius: .375rem;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  display: flex;
}

.block-tools__trigger > svg {
  --tw-text-opacity: 1;
  width: 100%;
  height: 100%;
  color: var(--fallback-pc, oklch(var(--pc) / var(--tw-text-opacity, 1)));
}

.block-tools__trigger--inverted {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: var(--fallback-p, oklch(var(--p) / var(--tw-border-opacity, 1)));
  --tw-bg-opacity: 1;
  background-color: var(--fallback-pc, oklch(var(--pc) / var(--tw-bg-opacity, 1)));
}

.block-tools__trigger--inverted > svg {
  --tw-text-opacity: 1;
  width: 100%;
  height: 100%;
  color: var(--fallback-p, oklch(var(--p) / var(--tw-text-opacity, 1)));
}

.block-tools {
  visibility: hidden;
  position: absolute;
}

.block-tools:hover, .block-tools:focus-within {
  z-index: 30;
}

.block-tools-btn {
  --tw-translate-y: -50%;
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  top: 50%;
  left: var(--hover-left);
}

.block-tools--right {
  --tw-translate-y: -50%;
  --tw-translate-x: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  top: 0;
  right: var(--hover-left);
}

.block-tools--right > .block-tools__contents {
  top: 50%;
  left: 0;
  transform: translate(-100%, -50%);
}

.block-tools--bottom {
  --tw-translate-y: 33.3333%;
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  left: 50%;
  bottom: var(--hover-bottom);
}

.block-tools--bottom > .block-tools__contents {
  background-color: #0000;
  padding-bottom: 2.25rem;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
}

.block-tools--left {
  --tw-translate-y: -50%;
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  top: 50%;
  left: var(--hover-left);
}

.block-tools--left > .block-tools__contents {
  top: 50%;
  right: 0;
  transform: translate(100%, -50%);
}

.block-tools--parent {
  left: calc(var(--hover-left)  - 1rem);
}

.block-tools__contents {
  z-index: 10;
  align-items: center;
  min-height: 4rem;
  display: flex;
  position: absolute;
}

.block-tools__contents > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.25rem * var(--tw-space-x-reverse));
  margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.block-tools__contents {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity, 1)));
  visibility: hidden;
  padding: .5rem;
}

.block-tools:hover > .block-tools__contents, .block-tools:focus-within > .block-tools__contents {
  visibility: visible;
}

.block-tools__contents > .block-tools__trigger {
  margin: .5rem;
}

.add-block__dialog {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-border-opacity, 1)));
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity, 1)));
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  z-index: 101;
  border-radius: .375rem;
  display: flex;
}

.add-block__dialog > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.25rem * var(--tw-space-x-reverse));
  margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.add-block__dialog {
  padding: .5rem;
}

.highlight-box {
  --hl-bc: color-mix(in oklab, oklch(var(--n)), black 7%);
  color: var(--hl-bc);
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  padding: .5rem;
  position: relative;
}

.highlight-box--primary, .highlight-box--secondary, .highlight-box--accent {
  --tw-prose-body: var(--fallback-bc, oklch(from var(--hl-bc) l c h / 80%));
  --tw-prose-headings: var(--fallback-bc, oklch(from var(--hl-bc) l c h / 100%));
  --tw-prose-lead: var(--fallback-bc, oklch(from var(--hl-bc) l c h / 100%));
  --tw-prose-links: var(--fallback-bc, oklch(from var(--hl-bc) l c h / 100%));
  --tw-prose-bold: var(--fallback-bc, oklch(from var(--hl-bc) l c h / 100%));
  --tw-prose-counters: var(--fallback-bc, oklch(from var(--hl-bc) l c h / 100%));
  --tw-prose-bullets: var(--fallback-bc, oklch(from var(--hl-bc) l c h / 50%));
  --tw-prose-hr: var(--fallback-bc, oklch(from var(--hl-bc) l c h / 20%));
  --tw-prose-quotes: var(--fallback-bc, oklch(from var(--hl-bc) l c h / 100%));
  --tw-prose-quote-borders: var(--fallback-bc, oklch(from var(--hl-bc) l c h / 20%));
  --tw-prose-captions: var(--fallback-bc, oklch(from var(--hl-bc) l c h / 50%));
  --tw-prose-code: var(--fallback-bc, oklch(from var(--hl-bc) l c h / 100%));
  --tw-prose-th-borders: var(--fallback-bc, oklch(from var(--hl-bc) l c h / 50%));
  --tw-prose-td-borders: var(--fallback-bc, oklch(from var(--hl-bc) l c h / 20%));
}

.highlight-box:before {
  content: "";
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.highlight-box--primary:before, .highlight-box--primary .block--highlighted:before {
  background: var(--highlight-1);
}

.highlight-box--secondary:before, .highlight-box--secondary .block--highlighted:before {
  background: var(--highlight-2);
}

.highlight-box--accent:before, .highlight-box--accent .block--highlighted:before {
  background: var(--highlight-3);
}

.highlight-box strong, .highlight-box h3, .highlight-box h4, .highlight-box h5, .highlight-box h6, .highlight-box li::marker {
  color: var(--hl-bc);
}

.highlight-box a:focus-visible {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--hl-bc);
}

.highlight-box-modifier {
  border-radius: .25rem;
  width: 2rem;
  height: 2rem;
  margin-left: .25rem;
  margin-right: .25rem;
}

.highlight-box-modifier--primary {
  background: var(--highlight-1);
}

.highlight-box-modifier--secondary {
  background: var(--highlight-2);
}

.highlight-box-modifier--accent {
  background: var(--highlight-3);
}

.image {
  position: relative;
}

.image:hover .image__bg, .image:has(:focus-within) .image__bg {
  opacity: .7;
}

.image:hover .image__upload, .image:has(:focus-within) .image__upload {
  opacity: 1;
}

.image__bg {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity, 1));
  opacity: 0;
  width: 100%;
  height: 100%;
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  top: 0;
  left: 0;
}

.image__upload {
  --tw-translate-x: -50%;
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity: 0;
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  top: 50%;
  left: 50%;
}

.example-block {
  --tw-border-opacity: 1;
  border-style: dotted;
  border-left-width: 2px;
  border-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-border-opacity, 1)));
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  padding-left: 1.25rem;
}

.example-block p {
  margin: 0;
}

.example-block p + p, .example-block .block + .block p {
  padding-top: 1.25rem;
}

.editor-contents :not(:where([class~="not-prose"], [class~="not-prose"] *)):where(h3) {
  margin-top: 1.6em;
  margin-bottom: .8em;
  font-size: 1.4286em;
  line-height: 1.4;
}

.editor-contents :not(:where([class~="not-prose"], [class~="not-prose"] *)):where(h4) {
  margin-top: 1.5556em;
  margin-bottom: .4444em;
  font-size: 1.2857em;
  line-height: 1.5556;
}

.editor-contents :not(:where([class~="not-prose"], [class~="not-prose"] *)):where(h5) {
  margin-top: 1.4286em;
  margin-bottom: .5714em;
  font-size: 1.1571em;
  line-height: 1.4286;
}

.editor-contents :not(:where([class~="not-prose"], [class~="not-prose"] *)):where(h6) {
  margin-top: 1.2857em;
  margin-bottom: .5143em;
  font-size: 1.0414em;
  line-height: 1.2857;
}

.editor-contents :not(:where([class~="not-prose"], [class~="not-prose"] *)):where(h3 code) {
  font-size: .9em;
}

.editor-contents :not(:where([class~="not-prose"], [class~="not-prose"] *)):where(h4 code), .editor-contents :not(:where([class~="not-prose"], [class~="not-prose"] *)):where(h5 code), .editor-contents :not(:where([class~="not-prose"], [class~="not-prose"] *)):where(h6 code) {
  font-size: .8889em;
}

@media (width >= 640px) {
  .editor-contents :not(:where([class~="not-prose"], [class~="not-prose"] *)):where(h3) {
    margin-top: 2em;
    margin-bottom: 1em;
    font-size: 1.5em;
    font-weight: 700;
    line-height: 1.3333;
  }

  .editor-contents :not(:where([class~="not-prose"], [class~="not-prose"] *)):where(h3 strong) {
    font-weight: 800;
  }

  .editor-contents :not(:where([class~="not-prose"], [class~="not-prose"] *)):where(h4) {
    margin-top: 1.6em;
    margin-bottom: .6em;
    font-size: 1.25em;
    font-weight: 600;
    line-height: 1.6;
  }

  .editor-contents :not(:where([class~="not-prose"], [class~="not-prose"] *)):where(h4 strong) {
    font-weight: 700;
  }

  .editor-contents :not(:where([class~="not-prose"], [class~="not-prose"] *)):where(h5) {
    margin-top: 1.5em;
    margin-bottom: .5em;
    font-size: 1.116em;
    font-weight: 600;
    line-height: 1.5;
  }

  .editor-contents :not(:where([class~="not-prose"], [class~="not-prose"] *)):where(h6) {
    margin-top: 1.35em;
    margin-bottom: .45em;
    font-size: 1.004em;
    font-weight: 540;
    line-height: 1.35;
  }

  .editor-contents :not(:where([class~="not-prose"], [class~="not-prose"] *)):where(h5 strong) {
    font-weight: 700;
  }

  .editor-contents :not(:where([class~="not-prose"], [class~="not-prose"] *)):where(h3 code) {
    font-size: .875em;
  }

  .editor-contents :not(:where([class~="not-prose"], [class~="not-prose"] *)):where(h4 code), .editor-contents :not(:where([class~="not-prose"], [class~="not-prose"] *)):where(h5 code), .editor-contents :not(:where([class~="not-prose"], [class~="not-prose"] *)):where(h6 code) {
    font-size: .9em;
  }
}

@media (width >= 1024px) {
  .editor-contents :not(:where([class~="not-prose"], [class~="not-prose"] *)):where(h3) {
    margin-top: 1.8667em;
    margin-bottom: 1.0667em;
    font-size: 1.6667em;
    line-height: 1.3333;
  }

  .editor-contents :not(:where([class~="not-prose"], [class~="not-prose"] *)):where(h4) {
    margin-top: 1.6667em;
    margin-bottom: .6667em;
    font-size: 1.3333em;
    line-height: 1.5;
  }

  .editor-contents :not(:where([class~="not-prose"], [class~="not-prose"] *)):where(h5) {
    margin-top: 1.7778em;
    margin-bottom: .4444em;
    font-size: 1.2em;
    line-height: 1.5556;
  }

  .editor-contents :not(:where([class~="not-prose"], [class~="not-prose"] *)):where(h6) {
    margin-top: 1.6em;
    margin-bottom: .4em;
    font-size: 1.08em;
    line-height: 1.4;
  }

  .editor-contents :not(:where([class~="not-prose"], [class~="not-prose"] *)):where(h3 code) {
    font-size: .8667em;
  }

  .editor-contents :not(:where([class~="not-prose"], [class~="not-prose"] *)):where(h4 code), .editor-contents :not(:where([class~="not-prose"], [class~="not-prose"] *)):where(h5 code), .editor-contents :not(:where([class~="not-prose"], [class~="not-prose"] *)):where(h6 code) {
    font-size: .875em;
  }
}

@media (width >= 1280px) {
  .editor-contents :not(:where([class~="not-prose"], [class~="not-prose"] *)):where(h3) {
    margin-top: 1.5556em;
    margin-bottom: .8889em;
    font-size: 1.8em;
    line-height: 1.1111;
  }

  .editor-contents :not(:where([class~="not-prose"], [class~="not-prose"] *)):where(h4) {
    margin-top: 1.6em;
    margin-bottom: .6667em;
    font-size: 1.5em;
    line-height: 1.3333;
  }

  .editor-contents :not(:where([class~="not-prose"], [class~="not-prose"] *)):where(h5) {
    margin-top: 1.8em;
    margin-bottom: .6em;
    font-size: 1.35em;
    line-height: 1.6;
  }

  .editor-contents :not(:where([class~="not-prose"], [class~="not-prose"] *)):where(h6) {
    margin-top: 1.62em;
    margin-bottom: .54em;
    font-size: 1.215em;
    line-height: 1.44;
  }

  .editor-contents :not(:where([class~="not-prose"], [class~="not-prose"] *)):where(h3 code) {
    font-size: .8611em;
  }

  .editor-contents :not(:where([class~="not-prose"], [class~="not-prose"] *)):where(h4 code), .editor-contents :not(:where([class~="not-prose"], [class~="not-prose"] *)):where(h5 code), .editor-contents :not(:where([class~="not-prose"], [class~="not-prose"] *)):where(h6 code) {
    font-size: .9em;
  }
}

.odd\:bg-base-200:nth-child(odd) {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-bg-opacity, 1)));
}

.focus-within\:bg-primary:focus-within {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-p, oklch(var(--p) / var(--tw-bg-opacity, 1)));
}

.focus-within\:text-primary-content:focus-within {
  --tw-text-opacity: 1;
  color: var(--fallback-pc, oklch(var(--pc) / var(--tw-text-opacity, 1)));
}

.focus-within\:outline-none:focus-within {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus-within\:outline:focus-within {
  outline-style: solid;
}

.hover\:cursor-pointer:hover {
  cursor: pointer;
}

.hover\:bg-primary:hover {
  --tw-bg-opacity: 1;
  background-color: var(--fallback-p, oklch(var(--p) / var(--tw-bg-opacity, 1)));
}

.hover\:text-primary-content:hover {
  --tw-text-opacity: 1;
  color: var(--fallback-pc, oklch(var(--pc) / var(--tw-text-opacity, 1)));
}

.focus\:z-10:focus {
  z-index: 10;
}

.focus\:border-red-mylim:focus {
  --tw-border-opacity: 1;
  border-color: rgb(236 93 87 / var(--tw-border-opacity, 1));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-red-mylim:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(236 93 87 / var(--tw-ring-opacity, 1));
}

.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

.focus-visible\:outline:focus-visible {
  outline-style: solid;
}

@media (width >= 640px) {
  .sm\:order-1 {
    order: 1;
  }

  .sm\:order-2 {
    order: 2;
  }

  .sm\:order-3 {
    order: 3;
  }

  .sm\:max-w-min {
    max-width: min-content;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }
}

@media (width >= 1024px) {
  .lg\:block {
    display: block;
  }

  .lg\:max-h-52 {
    max-height: 13rem;
  }

  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
/*# sourceMappingURL=index.1b687b8b.css.map */
