@import "./variables.css";
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "./fonts";
@import "./base.css";
@import "./layout.css";
@import "./daisyui-override.css";
@import "./themes.css";
@import "./animations.css";
@import "./components.css";
@import "./block.css";
@import "./tailwindcss-typography-override.css";
